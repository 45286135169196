function init_fullcalendar(){
    // initialize the calendar...
    var current = 'month' ;
    var clock = $('#calendar').fullCalendar({

        contentHeight: 'auto',
        header: {
            left: 'prev,next today',
            center: 'title',
            right: false
        },
        buttonText: {
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
        },
        dayClick: function(date, jsEvent, view) {
            $.ajax({
                dataType: 'script',
                type: 'GET',
                data:{date:date.format()},
                url: '/' + I18n.locale + '/logs'
            });
            $('#log_date').val(date.format());
            $('#logs_for').html(date.format());

        },

        viewRender: function( view, element ){
            if( view.name != current){
                current = view.name;
//                $.ajax({
//                    dataType: 'script',
//                    type: 'GET',
//                    async: false,
//                    data:{view: view.name, date: new Date(view.start)},
//                    url: '/logs/load_view'
//                });
                $('#calendar_div').toggleClass('month basicWeek');
            }
            if (view.name == 'month'){
                $.ajax({
                    dataType: 'script',
                    type: 'GET',
                    async: false,
                    data:{view: view.name, date: $.datepicker.formatDate('yy-mm-dd', new Date())},
                    url: '/logs/load_view'
                });
                $('#logs_for').html($.datepicker.formatDate('yy-mm-dd', new Date()));
            }
            if (view.name == 'basicWeek'){
                $.ajax({
                    dataType: 'script',
                    type: 'GET',
                    async: false,
                    data:{view: view.name, date: new Date(view.start)},
                    url: '/logs/load_view'
                });
                var count = 0;
                $('#hours_div_inner input[type=hidden]').each(function (){
                    var now = new Date(view.start);
                    now.setDate(now.getDate() + count);
                    $(this).attr('value',now);
                    count+=1;
                });
                var start = $.datepicker.formatDate('yy-mm-dd', new Date(view.start));
                var end = new Date(view.end);
                end.setDate(end.getDate() - 1);
                var end = $.datepicker.formatDate('yy-mm-dd', end);
                $('#logs_for').html(start + ' to ' + end);
            }
            $('#log_date').val($.datepicker.formatDate('yy-mm-dd', new Date()) );
        },
        events: '/logs/events.json'
//        eventClick: function(event) {
//            $('#log_date').val(event.date.format());
//            $('#logs_for').html(event.date.format());
//        }

    });
}

$(document).ready(function() {
//    $('select').material_select();
    init_fullcalendar();
});
