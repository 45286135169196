/*!
 * FullCalendar v2.0.2
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

//= require moment.min

(function(factory) {
    if (typeof define === 'function' && define.amd) {
        define([ 'jquery', 'moment' ], factory);
    }
    else {
        factory(jQuery, moment);
    }
})(function($, moment) {

    ;;

    var defaults = {

        lang: 'en',

        defaultTimedEventDuration: '02:00:00',
        defaultAllDayEventDuration: { days: 1 },
        forceEventDuration: false,
        nextDayThreshold: '09:00:00', // 9am

        // display
        defaultView: 'month',
        aspectRatio: 1.35,
        header: {
            left: 'title',
            center: '',
            right: 'today prev,next'
        },
        weekends: true,
        weekNumbers: false,

        weekNumberTitle: 'W',
        weekNumberCalculation: 'local',

        //editable: false,

        // event ajax
        lazyFetching: true,
        startParam: 'start',
        endParam: 'end',
        timezoneParam: 'timezone',

        timezone: false,

        //allDayDefault: undefined,

        // time formats
        titleFormat: {
            month: 'MMMM YYYY', // like "September 1986". each language will override this
            week: 'll', // like "Sep 4 1986"
            day: 'LL' // like "September 4 1986"
        },
        columnFormat: {
            month: 'ddd', // like "Sat"
            week: generateWeekColumnFormat,
            day: 'dddd' // like "Saturday"
        },
        timeFormat: { // for event elements
            'default': generateShortTimeFormat
        },

        displayEventEnd: {
            month: false,
            basicWeek: false,
            'default': true
        },

        // locale
        isRTL: false,
        defaultButtonText: {
            prev: "prev",
            next: "next",
            prevYear: "prev year",
            nextYear: "next year",
            today: 'today',
            month: 'month',
            week: 'week',
            day: 'day'
        },

        buttonIcons: {
            prev: 'left-single-arrow',
            next: 'right-single-arrow',
            prevYear: 'left-double-arrow',
            nextYear: 'right-double-arrow'
        },

        // jquery-ui theming
        theme: false,
        themeButtonIcons: {
            prev: 'circle-triangle-w',
            next: 'circle-triangle-e',
            prevYear: 'seek-prev',
            nextYear: 'seek-next'
        },

        //selectable: false,
        unselectAuto: true,

        dropAccept: '*',

        handleWindowResize: true,
        windowResizeDelay: 200 // milliseconds before a rerender happens

    };


    function generateShortTimeFormat(options, langData) {
        return langData.longDateFormat('LT')
            .replace(':mm', '(:mm)')
            .replace(/(\Wmm)$/, '($1)') // like above, but for foreign langs
            .replace(/\s*a$/i, 't'); // convert to AM/PM/am/pm to lowercase one-letter. remove any spaces beforehand
    }


    function generateWeekColumnFormat(options, langData) {
        var format = langData.longDateFormat('L'); // for the format like "MM/DD/YYYY"
        format = format.replace(/^Y+[^\w\s]*|[^\w\s]*Y+$/g, ''); // strip the year off the edge, as well as other misc non-whitespace chars
        if (options.isRTL) {
            format += ' ddd'; // for RTL, add day-of-week to end
        }
        else {
            format = 'ddd ' + format; // for LTR, add day-of-week to beginning
        }
        return format;
    }


    var langOptionHash = {
        en: {
            columnFormat: {
                week: 'ddd M/D' // override for english. different from the generated default, which is MM/DD
            }
        }
    };


// right-to-left defaults
    var rtlDefaults = {
        header: {
            left: 'next,prev today',
            center: '',
            right: 'title'
        },
        buttonIcons: {
            prev: 'right-single-arrow',
            next: 'left-single-arrow',
            prevYear: 'right-double-arrow',
            nextYear: 'left-double-arrow'
        },
        themeButtonIcons: {
            prev: 'circle-triangle-e',
            next: 'circle-triangle-w',
            nextYear: 'seek-prev',
            prevYear: 'seek-next'
        }
    };



    ;;

    var fc = $.fullCalendar = { version: "2.0.2" };
    var fcViews = fc.views = {};


    $.fn.fullCalendar = function(options) {
        var args = Array.prototype.slice.call(arguments, 1); // for a possible method call
        var res = this; // what this function will return (this jQuery object by default)

        this.each(function(i, _element) { // loop each DOM element involved
            var element = $(_element);
            var calendar = element.data('fullCalendar'); // get the existing calendar object (if any)
            var singleRes; // the returned value of this single method call

            // a method call
            if (typeof options === 'string') {
                if (calendar && $.isFunction(calendar[options])) {
                    singleRes = calendar[options].apply(calendar, args);
                    if (!i) {
                        res = singleRes; // record the first method call result
                    }
                    if (options === 'destroy') { // for the destroy method, must remove Calendar object data
                        element.removeData('fullCalendar');
                    }
                }
            }
            // a new calendar initialization
            else if (!calendar) { // don't initialize twice
                calendar = new Calendar(element, options);
                element.data('fullCalendar', calendar);
                calendar.render();
            }
        });

        return res;
    };


// function for adding/overriding defaults
    function setDefaults(d) {
        mergeOptions(defaults, d);
    }


// Recursively combines option hash-objects.
// Better than `$.extend(true, ...)` because arrays are not traversed/copied.
//
// called like:
//     mergeOptions(target, obj1, obj2, ...)
//
    function mergeOptions(target) {

        function mergeIntoTarget(name, value) {
            if ($.isPlainObject(value) && $.isPlainObject(target[name]) && !isForcedAtomicOption(name)) {
                // merge into a new object to avoid destruction
                target[name] = mergeOptions({}, target[name], value); // combine. `value` object takes precedence
            }
            else if (value !== undefined) { // only use values that are set and not undefined
                target[name] = value;
            }
        }

        for (var i=1; i<arguments.length; i++) {
            $.each(arguments[i], mergeIntoTarget);
        }

        return target;
    }


// overcome sucky view-option-hash and option-merging behavior messing with options it shouldn't
    function isForcedAtomicOption(name) {
        // Any option that ends in "Time" or "Duration" is probably a Duration,
        // and these will commonly be specified as plain objects, which we don't want to mess up.
        return /(Time|Duration)$/.test(name);
    }
// FIX: find a different solution for view-option-hashes and have a whitelist
// for options that can be recursively merged.

    ;;

//var langOptionHash = {}; // initialized in defaults.js
    fc.langs = langOptionHash; // expose


// Initialize jQuery UI Datepicker translations while using some of the translations
// for our own purposes. Will set this as the default language for datepicker.
// Called from a translation file.
    fc.datepickerLang = function(langCode, datepickerLangCode, options) {
        var langOptions = langOptionHash[langCode];

        // initialize FullCalendar's lang hash for this language
        if (!langOptions) {
            langOptions = langOptionHash[langCode] = {};
        }

        // merge certain Datepicker options into FullCalendar's options
        mergeOptions(langOptions, {
            isRTL: options.isRTL,
            weekNumberTitle: options.weekHeader,
            titleFormat: {
                month: options.showMonthAfterYear ?
                    'YYYY[' + options.yearSuffix + '] MMMM' :
                    'MMMM YYYY[' + options.yearSuffix + ']'
            },
            defaultButtonText: {
                // the translations sometimes wrongly contain HTML entities
                prev: stripHTMLEntities(options.prevText),
                next: stripHTMLEntities(options.nextText),
                today: stripHTMLEntities(options.currentText)
            }
        });

        // is jQuery UI Datepicker is on the page?
        if ($.datepicker) {

            // Register the language data.
            // FullCalendar and MomentJS use language codes like "pt-br" but Datepicker
            // does it like "pt-BR" or if it doesn't have the language, maybe just "pt".
            // Make an alias so the language can be referenced either way.
            $.datepicker.regional[datepickerLangCode] =
                $.datepicker.regional[langCode] = // alias
                    options;

            // Alias 'en' to the default language data. Do this every time.
            $.datepicker.regional.en = $.datepicker.regional[''];

            // Set as Datepicker's global defaults.
            $.datepicker.setDefaults(options);
        }
    };


// Sets FullCalendar-specific translations. Also sets the language as the global default.
// Called from a translation file.
    fc.lang = function(langCode, options) {
        var langOptions;

        if (options) {
            langOptions = langOptionHash[langCode];

            // initialize the hash for this language
            if (!langOptions) {
                langOptions = langOptionHash[langCode] = {};
            }

            mergeOptions(langOptions, options || {});
        }

        // set it as the default language for FullCalendar
        defaults.lang = langCode;
    };
    ;;


    function Calendar(element, instanceOptions) {
        var t = this;



        // Build options object
        // -----------------------------------------------------------------------------------
        // Precedence (lowest to highest): defaults, rtlDefaults, langOptions, instanceOptions

        instanceOptions = instanceOptions || {};

        var options = mergeOptions({}, defaults, instanceOptions);
        var langOptions;

        // determine language options
        if (options.lang in langOptionHash) {
            langOptions = langOptionHash[options.lang];
        }
        else {
            langOptions = langOptionHash[defaults.lang];
        }

        if (langOptions) { // if language options exist, rebuild...
            options = mergeOptions({}, defaults, langOptions, instanceOptions);
        }

        if (options.isRTL) { // is isRTL, rebuild...
            options = mergeOptions({}, defaults, rtlDefaults, langOptions || {}, instanceOptions);
        }



        // Exports
        // -----------------------------------------------------------------------------------

        t.options = options;
        t.render = render;
        t.destroy = destroy;
        t.refetchEvents = refetchEvents;
        t.reportEvents = reportEvents;
        t.reportEventChange = reportEventChange;
        t.rerenderEvents = rerenderEvents;
        t.changeView = changeView;
        t.select = select;
        t.unselect = unselect;
        t.prev = prev;
        t.next = next;
        t.prevYear = prevYear;
        t.nextYear = nextYear;
        t.today = today;
        t.gotoDate = gotoDate;
        t.incrementDate = incrementDate;
        t.getDate = getDate;
        t.getCalendar = getCalendar;
        t.getView = getView;
        t.option = option;
        t.trigger = trigger;



        // Language-data Internals
        // -----------------------------------------------------------------------------------
        // Apply overrides to the current language's data

        var langData = createObject( // make a cheap clone
            moment.langData(options.lang)
        );

        if (options.monthNames) {
            langData._months = options.monthNames;
        }
        if (options.monthNamesShort) {
            langData._monthsShort = options.monthNamesShort;
        }
        if (options.dayNames) {
            langData._weekdays = options.dayNames;
        }
        if (options.dayNamesShort) {
            langData._weekdaysShort = options.dayNamesShort;
        }
        if (options.firstDay != null) {
            var _week = createObject(langData._week); // _week: { dow: # }
            _week.dow = options.firstDay;
            langData._week = _week;
        }



        // Calendar-specific Date Utilities
        // -----------------------------------------------------------------------------------


        t.defaultAllDayEventDuration = moment.duration(options.defaultAllDayEventDuration);
        t.defaultTimedEventDuration = moment.duration(options.defaultTimedEventDuration);


        // Builds a moment using the settings of the current calendar: timezone and language.
        // Accepts anything the vanilla moment() constructor accepts.
        t.moment = function() {
            var mom;

            if (options.timezone === 'local') {
                mom = fc.moment.apply(null, arguments);

                // Force the moment to be local, because fc.moment doesn't guarantee it.
                if (mom.hasTime()) { // don't give ambiguously-timed moments a local zone
                    mom.local();
                }
            }
            else if (options.timezone === 'UTC') {
                mom = fc.moment.utc.apply(null, arguments); // process as UTC
            }
            else {
                mom = fc.moment.parseZone.apply(null, arguments); // let the input decide the zone
            }

            mom._lang = langData;

            return mom;
        };


        // Returns a boolean about whether or not the calendar knows how to calculate
        // the timezone offset of arbitrary dates in the current timezone.
        t.getIsAmbigTimezone = function() {
            return options.timezone !== 'local' && options.timezone !== 'UTC';
        };


        // Returns a copy of the given date in the current timezone of it is ambiguously zoned.
        // This will also give the date an unambiguous time.
        t.rezoneDate = function(date) {
            return t.moment(date.toArray());
        };


        // Returns a moment for the current date, as defined by the client's computer,
        // or overridden by the `now` option.
        t.getNow = function() {
            var now = options.now;
            if (typeof now === 'function') {
                now = now();
            }
            return t.moment(now);
        };


        // Calculates the week number for a moment according to the calendar's
        // `weekNumberCalculation` setting.
        t.calculateWeekNumber = function(mom) {
            var calc = options.weekNumberCalculation;

            if (typeof calc === 'function') {
                return calc(mom);
            }
            else if (calc === 'local') {
                return mom.week();
            }
            else if (calc.toUpperCase() === 'ISO') {
                return mom.isoWeek();
            }
        };


        // Get an event's normalized end date. If not present, calculate it from the defaults.
        t.getEventEnd = function(event) {
            if (event.end) {
                return event.end.clone();
            }
            else {
                return t.getDefaultEventEnd(event.allDay, event.start);
            }
        };


        // Given an event's allDay status and start date, return swhat its fallback end date should be.
        t.getDefaultEventEnd = function(allDay, start) { // TODO: rename to computeDefaultEventEnd
            var end = start.clone();

            if (allDay) {
                end.stripTime().add(t.defaultAllDayEventDuration);
            }
            else {
                end.add(t.defaultTimedEventDuration);
            }

            if (t.getIsAmbigTimezone()) {
                end.stripZone(); // we don't know what the tzo should be
            }

            return end;
        };



        // Date-formatting Utilities
        // -----------------------------------------------------------------------------------


        // Like the vanilla formatRange, but with calendar-specific settings applied.
        t.formatRange = function(m1, m2, formatStr) {

            // a function that returns a formatStr // TODO: in future, precompute this
            if (typeof formatStr === 'function') {
                formatStr = formatStr.call(t, options, langData);
            }

            return formatRange(m1, m2, formatStr, null, options.isRTL);
        };


        // Like the vanilla formatDate, but with calendar-specific settings applied.
        t.formatDate = function(mom, formatStr) {

            // a function that returns a formatStr // TODO: in future, precompute this
            if (typeof formatStr === 'function') {
                formatStr = formatStr.call(t, options, langData);
            }

            return formatDate(mom, formatStr);
        };



        // Imports
        // -----------------------------------------------------------------------------------


        EventManager.call(t, options);
        var isFetchNeeded = t.isFetchNeeded;
        var fetchEvents = t.fetchEvents;



        // Locals
        // -----------------------------------------------------------------------------------


        var _element = element[0];
        var header;
        var headerElement;
        var content;
        var tm; // for making theme classes
        var currentView;
        var elementOuterWidth;
        var suggestedViewHeight;
        var resizeUID = 0;
        var ignoreWindowResize = 0;
        var date;
        var events = [];
        var _dragElement;



        // Main Rendering
        // -----------------------------------------------------------------------------------


        if (options.defaultDate != null) {
            date = t.moment(options.defaultDate);
        }
        else {
            date = t.getNow();
        }


        function render(inc) {
            if (!content) {
                initialRender();
            }
            else if (elementVisible()) {
                // mainly for the public API
                calcSize();
                _renderView(inc);
            }
        }


        function initialRender() {
            tm = options.theme ? 'ui' : 'fc';
            element.addClass('fc');
            if (options.isRTL) {
                element.addClass('fc-rtl');
            }
            else {
                element.addClass('fc-ltr');
            }
            if (options.theme) {
                element.addClass('ui-widget');
            }

            content = $("<div class='fc-content' />")
                .prependTo(element);

            header = new Header(t, options);
            headerElement = header.render();
            if (headerElement) {
                element.prepend(headerElement);
            }

            changeView(options.defaultView);

            if (options.handleWindowResize) {
                $(window).resize(windowResize);
            }

            // needed for IE in a 0x0 iframe, b/c when it is resized, never triggers a windowResize
            if (!bodyVisible()) {
                lateRender();
            }
        }


        // called when we know the calendar couldn't be rendered when it was initialized,
        // but we think it's ready now
        function lateRender() {
            setTimeout(function() { // IE7 needs this so dimensions are calculated correctly
                if (!currentView.start && bodyVisible()) { // !currentView.start makes sure this never happens more than once
                    renderView();
                }
            },0);
        }


        function destroy() {

            if (currentView) {
                trigger('viewDestroy', currentView, currentView, currentView.element);
                currentView.triggerEventDestroy();
            }

            $(window).unbind('resize', windowResize);

            if (options.droppable) {
                $(document)
                    .off('dragstart', droppableDragStart)
                    .off('dragstop', droppableDragStop);
            }

            if (currentView.selectionManagerDestroy) {
                currentView.selectionManagerDestroy();
            }

            header.destroy();
            content.remove();
            element.removeClass('fc fc-ltr fc-rtl ui-widget');
        }


        function elementVisible() {
            return element.is(':visible');
        }


        function bodyVisible() {
            return $('body').is(':visible');
        }



        // View Rendering
        // -----------------------------------------------------------------------------------


        function changeView(newViewName) {
            if (!currentView || newViewName != currentView.name) {
                _changeView(newViewName);
            }
        }


        function _changeView(newViewName) {
            ignoreWindowResize++;

            if (currentView) {
                trigger('viewDestroy', currentView, currentView, currentView.element);
                unselect();
                currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
                freezeContentHeight();
                currentView.element.remove();
                header.deactivateButton(currentView.name);
            }

            header.activateButton(newViewName);

            currentView = new fcViews[newViewName](
                $("<div class='fc-view fc-view-" + newViewName + "' />")
                    .appendTo(content),
                t // the calendar object
            );

            renderView();
            unfreezeContentHeight();

            ignoreWindowResize--;
        }


        function renderView(inc) {
            if (
                !currentView.start || // never rendered before
                inc || // explicit date window change
                !date.isWithin(currentView.intervalStart, currentView.intervalEnd) // implicit date window change
            ) {
                if (elementVisible()) {
                    _renderView(inc);
                }
            }
        }


        function _renderView(inc) { // assumes elementVisible
            ignoreWindowResize++;

            if (currentView.start) { // already been rendered?
                trigger('viewDestroy', currentView, currentView, currentView.element);
                unselect();
                clearEvents();
            }

            freezeContentHeight();
            if (inc) {
                date = currentView.incrementDate(date, inc);
            }
            currentView.render(date.clone()); // the view's render method ONLY renders the skeleton, nothing else
            setSize();
            unfreezeContentHeight();
            (currentView.afterRender || noop)();

            updateTitle();
            updateTodayButton();

            trigger('viewRender', currentView, currentView, currentView.element);

            ignoreWindowResize--;

            getAndRenderEvents();
        }



        // Resizing
        // -----------------------------------------------------------------------------------


        function updateSize() {
            if (elementVisible()) {
                unselect();
                clearEvents();
                calcSize();
                setSize();
                renderEvents();
            }
        }


        function calcSize() { // assumes elementVisible
            if (options.contentHeight) {
                suggestedViewHeight = options.contentHeight;
            }
            else if (options.height) {
                suggestedViewHeight = options.height - (headerElement ? headerElement.height() : 0) - vsides(content);
            }
            else {
                suggestedViewHeight = Math.round(content.width() / Math.max(options.aspectRatio, .5));
            }
        }


        function setSize() { // assumes elementVisible

            if (suggestedViewHeight === undefined) {
                calcSize(); // for first time
                // NOTE: we don't want to recalculate on every renderView because
                // it could result in oscillating heights due to scrollbars.
            }

            ignoreWindowResize++;
            currentView.setHeight(suggestedViewHeight);
            currentView.setWidth(content.width());
            ignoreWindowResize--;

            elementOuterWidth = element.outerWidth();
        }


        function windowResize(ev) {
            if (
                !ignoreWindowResize &&
                ev.target === window // so we don't process jqui "resize" events that have bubbled up
            ) {
                if (currentView.start) { // view has already been rendered
                    var uid = ++resizeUID;
                    setTimeout(function() { // add a delay
                        if (uid == resizeUID && !ignoreWindowResize && elementVisible()) {
                            if (elementOuterWidth != (elementOuterWidth = element.outerWidth())) {
                                ignoreWindowResize++; // in case the windowResize callback changes the height
                                updateSize();
                                currentView.trigger('windowResize', _element);
                                ignoreWindowResize--;
                            }
                        }
                    }, options.windowResizeDelay);
                }else{
                    // calendar must have been initialized in a 0x0 iframe that has just been resized
                    lateRender();
                }
            }
        }



        /* Event Fetching/Rendering
	-----------------------------------------------------------------------------*/
        // TODO: going forward, most of this stuff should be directly handled by the view


        function refetchEvents() { // can be called as an API method
            clearEvents();
            fetchAndRenderEvents();
        }


        function rerenderEvents(modifiedEventID) { // can be called as an API method
            clearEvents();
            renderEvents(modifiedEventID);
        }


        function renderEvents(modifiedEventID) { // TODO: remove modifiedEventID hack
            if (elementVisible()) {
                currentView.renderEvents(events, modifiedEventID); // actually render the DOM elements
                currentView.trigger('eventAfterAllRender');
            }
        }


        function clearEvents() {
            currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
            currentView.clearEvents(); // actually remove the DOM elements
            currentView.clearEventData(); // for View.js, TODO: unify with clearEvents
        }


        function getAndRenderEvents() {
            if (!options.lazyFetching || isFetchNeeded(currentView.start, currentView.end)) {
                fetchAndRenderEvents();
            }
            else {
                renderEvents();
            }
        }


        function fetchAndRenderEvents() {
            fetchEvents(currentView.start, currentView.end);
            // ... will call reportEvents
            // ... which will call renderEvents
        }


        // called when event data arrives
        function reportEvents(_events) {
            events = _events;
            renderEvents();
        }


        // called when a single event's data has been changed
        function reportEventChange(eventID) {
            rerenderEvents(eventID);
        }



        /* Header Updating
	-----------------------------------------------------------------------------*/


        function updateTitle() {
            header.updateTitle(currentView.title);
        }


        function updateTodayButton() {
            var now = t.getNow();
            if (now.isWithin(currentView.intervalStart, currentView.intervalEnd)) {
                header.disableButton('today');
            }
            else {
                header.enableButton('today');
            }
        }



        /* Selection
	-----------------------------------------------------------------------------*/


        function select(start, end) {
            currentView.select(start, end);
        }


        function unselect() { // safe to be called before renderView
            if (currentView) {
                currentView.unselect();
            }
        }



        /* Date
	-----------------------------------------------------------------------------*/


        function prev() {
            renderView(-1);
        }


        function next() {
            renderView(1);
        }


        function prevYear() {
            date.add('years', -1);
            renderView();
        }


        function nextYear() {
            date.add('years', 1);
            renderView();
        }


        function today() {
            date = t.getNow();
            renderView();
        }


        function gotoDate(dateInput) {
            date = t.moment(dateInput);
            renderView();
        }


        function incrementDate(delta) {
            date.add(moment.duration(delta));
            renderView();
        }


        function getDate() {
            return date.clone();
        }



        /* Height "Freezing"
	-----------------------------------------------------------------------------*/


        function freezeContentHeight() {
            content.css({
                width: '100%',
                height: content.height(),
                overflow: 'hidden'
            });
        }


        function unfreezeContentHeight() {
            content.css({
                width: '',
                height: '',
                overflow: ''
            });
        }



        /* Misc
	-----------------------------------------------------------------------------*/


        function getCalendar() {
            return t;
        }


        function getView() {
            return currentView;
        }


        function option(name, value) {
            if (value === undefined) {
                return options[name];
            }
            if (name == 'height' || name == 'contentHeight' || name == 'aspectRatio') {
                options[name] = value;
                updateSize();
            }
        }


        function trigger(name, thisObj) {
            if (options[name]) {
                return options[name].apply(
                    thisObj || _element,
                    Array.prototype.slice.call(arguments, 2)
                );
            }
        }



        /* External Dragging
	------------------------------------------------------------------------*/

        if (options.droppable) {
            // TODO: unbind on destroy
            $(document)
                .on('dragstart', droppableDragStart)
                .on('dragstop', droppableDragStop);
            // this is undone in destroy
        }

        function droppableDragStart(ev, ui) {
            var _e = ev.target;
            var e = $(_e);
            if (!e.parents('.fc').length) { // not already inside a calendar
                var accept = options.dropAccept;
                if ($.isFunction(accept) ? accept.call(_e, e) : e.is(accept)) {
                    _dragElement = _e;
                    currentView.dragStart(_dragElement, ev, ui);
                }
            }
        }

        function droppableDragStop(ev, ui) {
            if (_dragElement) {
                currentView.dragStop(_dragElement, ev, ui);
                _dragElement = null;
            }
        }


    }

    ;;

    function Header(calendar, options) {
        var t = this;


        // exports
        t.render = render;
        t.destroy = destroy;
        t.updateTitle = updateTitle;
        t.activateButton = activateButton;
        t.deactivateButton = deactivateButton;
        t.disableButton = disableButton;
        t.enableButton = enableButton;


        // locals
        var element = $([]);
        var tm;



        function render() {
            tm = options.theme ? 'ui' : 'fc';
            var sections = options.header;
            if (sections) {
                element = $("<table class='fc-header' style='width:100%'/>")
                    .append(
                        $("<tr/>")
                            .append(renderSection('left'))
                            .append(renderSection('center'))
                            .append(renderSection('right'))
                    );
                return element;
            }
        }


        function destroy() {
            element.remove();
        }


        function renderSection(position) {
            var e = $("<td class='fc-header-" + position + "'/>");
            var buttonStr = options.header[position];
            if (buttonStr) {
                $.each(buttonStr.split(' '), function(i) {
                    if (i > 0) {
                        e.append("<span class='fc-header-space'/>");
                    }
                    var prevButton;
                    $.each(this.split(','), function(j, buttonName) {
                        if (buttonName == 'title') {
                            e.append("<span class='fc-header-title'><h2>&nbsp;</h2></span>");
                            if (prevButton) {
                                prevButton.addClass(tm + '-corner-right');
                            }
                            prevButton = null;
                        }else{
                            var buttonClick;
                            if (calendar[buttonName]) {
                                buttonClick = calendar[buttonName]; // calendar method
                            }
                            else if (fcViews[buttonName]) {
                                buttonClick = function() {
                                    button.removeClass(tm + '-state-hover'); // forget why
                                    calendar.changeView(buttonName);
                                };
                            }
                            if (buttonClick) {

                                // smartProperty allows different text per view button (ex: "Agenda Week" vs "Basic Week")
                                var themeIcon = smartProperty(options.themeButtonIcons, buttonName);
                                var normalIcon = smartProperty(options.buttonIcons, buttonName);
                                var defaultText = smartProperty(options.defaultButtonText, buttonName);
                                var customText = smartProperty(options.buttonText, buttonName);
                                var html;

                                if (customText) {
                                    html = htmlEscape(customText);
                                }
                                else if (themeIcon && options.theme) {
                                    html = "<span class='ui-icon ui-icon-" + themeIcon + "'></span>";
                                }
                                else if (normalIcon && !options.theme) {
                                    html = "<span class='fc-icon fc-icon-" + normalIcon + "'></span>";
                                }
                                else {
                                    html = htmlEscape(defaultText || buttonName);
                                }

                                var button = $(
                                    "<span class='fc-button fc-button-" + buttonName + " " + tm + "-state-default'>" +
                                    html +
                                    "</span>"
                                )
                                    .click(function() {
                                        if (!button.hasClass(tm + '-state-disabled')) {
                                            buttonClick();
                                        }
                                    })
                                    .mousedown(function() {
                                        button
                                            .not('.' + tm + '-state-active')
                                            .not('.' + tm + '-state-disabled')
                                            .addClass(tm + '-state-down');
                                    })
                                    .mouseup(function() {
                                        button.removeClass(tm + '-state-down');
                                    })
                                    .hover(
                                        function() {
                                            button
                                                .not('.' + tm + '-state-active')
                                                .not('.' + tm + '-state-disabled')
                                                .addClass(tm + '-state-hover');
                                        },
                                        function() {
                                            button
                                                .removeClass(tm + '-state-hover')
                                                .removeClass(tm + '-state-down');
                                        }
                                    )
                                    .appendTo(e);
                                disableTextSelection(button);
                                if (!prevButton) {
                                    button.addClass(tm + '-corner-left');
                                }
                                prevButton = button;
                            }
                        }
                    });
                    if (prevButton) {
                        prevButton.addClass(tm + '-corner-right');
                    }
                });
            }
            return e;
        }


        function updateTitle(html) {
            element.find('h2')
                .html(html);
        }


        function activateButton(buttonName) {
            element.find('span.fc-button-' + buttonName)
                .addClass(tm + '-state-active');
        }


        function deactivateButton(buttonName) {
            element.find('span.fc-button-' + buttonName)
                .removeClass(tm + '-state-active');
        }


        function disableButton(buttonName) {
            element.find('span.fc-button-' + buttonName)
                .addClass(tm + '-state-disabled');
        }


        function enableButton(buttonName) {
            element.find('span.fc-button-' + buttonName)
                .removeClass(tm + '-state-disabled');
        }


    }

    ;;

    fc.sourceNormalizers = [];
    fc.sourceFetchers = [];

    var ajaxDefaults = {
        dataType: 'json',
        cache: false
    };

    var eventGUID = 1;


    function EventManager(options) { // assumed to be a calendar
        var t = this;


        // exports
        t.isFetchNeeded = isFetchNeeded;
        t.fetchEvents = fetchEvents;
        t.addEventSource = addEventSource;
        t.removeEventSource = removeEventSource;
        t.updateEvent = updateEvent;
        t.renderEvent = renderEvent;
        t.removeEvents = removeEvents;
        t.clientEvents = clientEvents;
        t.mutateEvent = mutateEvent;


        // imports
        var trigger = t.trigger;
        var getView = t.getView;
        var reportEvents = t.reportEvents;
        var getEventEnd = t.getEventEnd;


        // locals
        var stickySource = { events: [] };
        var sources = [ stickySource ];
        var rangeStart, rangeEnd;
        var currentFetchID = 0;
        var pendingSourceCnt = 0;
        var loadingLevel = 0;
        var cache = [];


        $.each(
            (options.events ? [ options.events ] : []).concat(options.eventSources || []),
            function(i, sourceInput) {
                var source = buildEventSource(sourceInput);
                if (source) {
                    sources.push(source);
                }
            }
        );



        /* Fetching
	-----------------------------------------------------------------------------*/


        function isFetchNeeded(start, end) {
            return !rangeStart || // nothing has been fetched yet?
                // or, a part of the new range is outside of the old range? (after normalizing)
                start.clone().stripZone() < rangeStart.clone().stripZone() ||
                end.clone().stripZone() > rangeEnd.clone().stripZone();
        }


        function fetchEvents(start, end) {
            rangeStart = start;
            rangeEnd = end;
            cache = [];
            var fetchID = ++currentFetchID;
            var len = sources.length;
            pendingSourceCnt = len;
            for (var i=0; i<len; i++) {
                fetchEventSource(sources[i], fetchID);
            }
        }


        function fetchEventSource(source, fetchID) {
            _fetchEventSource(source, function(events) {
                var isArraySource = $.isArray(source.events);
                var i;
                var event;

                if (fetchID == currentFetchID) {

                    if (events) {
                        for (i=0; i<events.length; i++) {
                            event = events[i];

                            // event array sources have already been convert to Event Objects
                            if (!isArraySource) {
                                event = buildEvent(event, source);
                            }

                            if (event) {
                                cache.push(event);
                            }
                        }
                    }

                    pendingSourceCnt--;
                    if (!pendingSourceCnt) {
                        reportEvents(cache);
                    }
                }
            });
        }


        function _fetchEventSource(source, callback) {
            var i;
            var fetchers = fc.sourceFetchers;
            var res;

            for (i=0; i<fetchers.length; i++) {
                res = fetchers[i].call(
                    t, // this, the Calendar object
                    source,
                    rangeStart.clone(),
                    rangeEnd.clone(),
                    options.timezone,
                    callback
                );

                if (res === true) {
                    // the fetcher is in charge. made its own async request
                    return;
                }
                else if (typeof res == 'object') {
                    // the fetcher returned a new source. process it
                    _fetchEventSource(res, callback);
                    return;
                }
            }

            var events = source.events;
            if (events) {
                if ($.isFunction(events)) {
                    pushLoading();
                    events.call(
                        t, // this, the Calendar object
                        rangeStart.clone(),
                        rangeEnd.clone(),
                        options.timezone,
                        function(events) {
                            callback(events);
                            popLoading();
                        }
                    );
                }
                else if ($.isArray(events)) {
                    callback(events);
                }
                else {
                    callback();
                }
            }else{
                var url = source.url;
                if (url) {
                    var success = source.success;
                    var error = source.error;
                    var complete = source.complete;

                    // retrieve any outbound GET/POST $.ajax data from the options
                    var customData;
                    if ($.isFunction(source.data)) {
                        // supplied as a function that returns a key/value object
                        customData = source.data();
                    }
                    else {
                        // supplied as a straight key/value object
                        customData = source.data;
                    }

                    // use a copy of the custom data so we can modify the parameters
                    // and not affect the passed-in object.
                    var data = $.extend({}, customData || {});

                    var startParam = firstDefined(source.startParam, options.startParam);
                    var endParam = firstDefined(source.endParam, options.endParam);
                    var timezoneParam = firstDefined(source.timezoneParam, options.timezoneParam);

                    if (startParam) {
                        data[startParam] = rangeStart.format();
                    }
                    if (endParam) {
                        data[endParam] = rangeEnd.format();
                    }
                    if (options.timezone && options.timezone != 'local') {
                        data[timezoneParam] = options.timezone;
                    }

                    pushLoading();
                    $.ajax($.extend({}, ajaxDefaults, source, {
                        data: data,
                        success: function(events) {
                            events = events || [];
                            var res = applyAll(success, this, arguments);
                            if ($.isArray(res)) {
                                events = res;
                            }
                            callback(events);
                        },
                        error: function() {
                            applyAll(error, this, arguments);
                            callback();
                        },
                        complete: function() {
                            applyAll(complete, this, arguments);
                            popLoading();
                        }
                    }));
                }else{
                    callback();
                }
            }
        }



        /* Sources
	-----------------------------------------------------------------------------*/


        function addEventSource(sourceInput) {
            var source = buildEventSource(sourceInput);
            if (source) {
                sources.push(source);
                pendingSourceCnt++;
                fetchEventSource(source, currentFetchID); // will eventually call reportEvents
            }
        }


        function buildEventSource(sourceInput) { // will return undefined if invalid source
            var normalizers = fc.sourceNormalizers;
            var source;
            var i;

            if ($.isFunction(sourceInput) || $.isArray(sourceInput)) {
                source = { events: sourceInput };
            }
            else if (typeof sourceInput === 'string') {
                source = { url: sourceInput };
            }
            else if (typeof sourceInput === 'object') {
                source = $.extend({}, sourceInput); // shallow copy

                if (typeof source.className === 'string') {
                    // TODO: repeat code, same code for event classNames
                    source.className = source.className.split(/\s+/);
                }
            }

            if (source) {

                // for array sources, we convert to standard Event Objects up front
                if ($.isArray(source.events)) {
                    source.events = $.map(source.events, function(eventInput) {
                        return buildEvent(eventInput, source);
                    });
                }

                for (i=0; i<normalizers.length; i++) {
                    normalizers[i].call(t, source);
                }

                return source;
            }
        }


        function removeEventSource(source) {
            sources = $.grep(sources, function(src) {
                return !isSourcesEqual(src, source);
            });
            // remove all client events from that source
            cache = $.grep(cache, function(e) {
                return !isSourcesEqual(e.source, source);
            });
            reportEvents(cache);
        }


        function isSourcesEqual(source1, source2) {
            return source1 && source2 && getSourcePrimitive(source1) == getSourcePrimitive(source2);
        }


        function getSourcePrimitive(source) {
            return ((typeof source == 'object') ? (source.events || source.url) : '') || source;
        }



        /* Manipulation
	-----------------------------------------------------------------------------*/


        function updateEvent(event) {

            event.start = t.moment(event.start);
            if (event.end) {
                event.end = t.moment(event.end);
            }

            mutateEvent(event);
            propagateMiscProperties(event);
            reportEvents(cache); // reports event modifications (so we can redraw)
        }


        var miscCopyableProps = [
            'title',
            'url',
            'allDay',
            'className',
            'editable',
            'color',
            'backgroundColor',
            'borderColor',
            'textColor'
        ];

        function propagateMiscProperties(event) {
            var i;
            var cachedEvent;
            var j;
            var prop;

            for (i=0; i<cache.length; i++) {
                cachedEvent = cache[i];
                if (cachedEvent._id == event._id && cachedEvent !== event) {
                    for (j=0; j<miscCopyableProps.length; j++) {
                        prop = miscCopyableProps[j];
                        if (event[prop] !== undefined) {
                            cachedEvent[prop] = event[prop];
                        }
                    }
                }
            }
        }



        function renderEvent(eventData, stick) {
            var event = buildEvent(eventData);
            if (event) {
                if (!event.source) {
                    if (stick) {
                        stickySource.events.push(event);
                        event.source = stickySource;
                    }
                    cache.push(event);
                }
                reportEvents(cache);
            }
        }


        function removeEvents(filter) {
            var eventID;
            var i;

            if (filter == null) { // null or undefined. remove all events
                filter = function() { return true; }; // will always match
            }
            else if (!$.isFunction(filter)) { // an event ID
                eventID = filter + '';
                filter = function(event) {
                    return event._id == eventID;
                };
            }

            // Purge event(s) from our local cache
            cache = $.grep(cache, filter, true); // inverse=true

            // Remove events from array sources.
            // This works because they have been converted to official Event Objects up front.
            // (and as a result, event._id has been calculated).
            for (i=0; i<sources.length; i++) {
                if ($.isArray(sources[i].events)) {
                    sources[i].events = $.grep(sources[i].events, filter, true);
                }
            }

            reportEvents(cache);
        }


        function clientEvents(filter) {
            if ($.isFunction(filter)) {
                return $.grep(cache, filter);
            }
            else if (filter != null) { // not null, not undefined. an event ID
                filter += '';
                return $.grep(cache, function(e) {
                    return e._id == filter;
                });
            }
            return cache; // else, return all
        }



        /* Loading State
	-----------------------------------------------------------------------------*/


        function pushLoading() {
            if (!(loadingLevel++)) {
                trigger('loading', null, true, getView());
            }
        }


        function popLoading() {
            if (!(--loadingLevel)) {
                trigger('loading', null, false, getView());
            }
        }



        /* Event Normalization
	-----------------------------------------------------------------------------*/

        function buildEvent(data, source) { // source may be undefined!
            var out = {};
            var start;
            var end;
            var allDay;
            var allDayDefault;

            if (options.eventDataTransform) {
                data = options.eventDataTransform(data);
            }
            if (source && source.eventDataTransform) {
                data = source.eventDataTransform(data);
            }

            start = t.moment(data.start || data.date); // "date" is an alias for "start"
            if (!start.isValid()) {
                return;
            }

            end = null;
            if (data.end) {
                end = t.moment(data.end);
                if (!end.isValid()) {
                    return;
                }
            }

            allDay = data.allDay;
            if (allDay === undefined) {
                allDayDefault = firstDefined(
                    source ? source.allDayDefault : undefined,
                    options.allDayDefault
                );
                if (allDayDefault !== undefined) {
                    // use the default
                    allDay = allDayDefault;
                }
                else {
                    // all dates need to have ambig time for the event to be considered allDay
                    allDay = !start.hasTime() && (!end || !end.hasTime());
                }
            }

            // normalize the date based on allDay
            if (allDay) {
                // neither date should have a time
                if (start.hasTime()) {
                    start.stripTime();
                }
                if (end && end.hasTime()) {
                    end.stripTime();
                }
            }
            else {
                // force a time/zone up the dates
                if (!start.hasTime()) {
                    start = t.rezoneDate(start);
                }
                if (end && !end.hasTime()) {
                    end = t.rezoneDate(end);
                }
            }

            // Copy all properties over to the resulting object.
            // The special-case properties will be copied over afterwards.
            $.extend(out, data);

            if (source) {
                out.source = source;
            }

            out._id = data._id || (data.id === undefined ? '_fc' + eventGUID++ : data.id + '');

            if (data.className) {
                if (typeof data.className == 'string') {
                    out.className = data.className.split(/\s+/);
                }
                else { // assumed to be an array
                    out.className = data.className;
                }
            }
            else {
                out.className = [];
            }

            out.allDay = allDay;
            out.start = start;
            out.end = end;

            if (options.forceEventDuration && !out.end) {
                out.end = getEventEnd(out);
            }

            backupEventDates(out);

            return out;
        }



        /* Event Modification Math
	-----------------------------------------------------------------------------------------*/


        // Modify the date(s) of an event and make this change propagate to all other events with
        // the same ID (related repeating events).
        //
        // If `newStart`/`newEnd` are not specified, the "new" dates are assumed to be `event.start` and `event.end`.
        // The "old" dates to be compare against are always `event._start` and `event._end` (set by EventManager).
        //
        // Returns an object with delta information and a function to undo all operations.
        //
        function mutateEvent(event, newStart, newEnd) {
            var oldAllDay = event._allDay;
            var oldStart = event._start;
            var oldEnd = event._end;
            var clearEnd = false;
            var newAllDay;
            var dateDelta;
            var durationDelta;
            var undoFunc;

            // if no new dates were passed in, compare against the event's existing dates
            if (!newStart && !newEnd) {
                newStart = event.start;
                newEnd = event.end;
            }

            // NOTE: throughout this function, the initial values of `newStart` and `newEnd` are
            // preserved. These values may be undefined.

            // detect new allDay
            if (event.allDay != oldAllDay) { // if value has changed, use it
                newAllDay = event.allDay;
            }
            else { // otherwise, see if any of the new dates are allDay
                newAllDay = !(newStart || newEnd).hasTime();
            }

            // normalize the new dates based on allDay
            if (newAllDay) {
                if (newStart) {
                    newStart = newStart.clone().stripTime();
                }
                if (newEnd) {
                    newEnd = newEnd.clone().stripTime();
                }
            }

            // compute dateDelta
            if (newStart) {
                if (newAllDay) {
                    dateDelta = dayishDiff(newStart, oldStart.clone().stripTime()); // treat oldStart as allDay
                }
                else {
                    dateDelta = dayishDiff(newStart, oldStart);
                }
            }

            if (newAllDay != oldAllDay) {
                // if allDay has changed, always throw away the end
                clearEnd = true;
            }
            else if (newEnd) {
                durationDelta = dayishDiff(
                    // new duration
                    newEnd || t.getDefaultEventEnd(newAllDay, newStart || oldStart),
                    newStart || oldStart
                ).subtract(dayishDiff(
                    // subtract old duration
                    oldEnd || t.getDefaultEventEnd(oldAllDay, oldStart),
                    oldStart
                ));
            }

            undoFunc = mutateEvents(
                clientEvents(event._id), // get events with this ID
                clearEnd,
                newAllDay,
                dateDelta,
                durationDelta
            );

            return {
                dateDelta: dateDelta,
                durationDelta: durationDelta,
                undo: undoFunc
            };
        }


        // Modifies an array of events in the following ways (operations are in order):
        // - clear the event's `end`
        // - convert the event to allDay
        // - add `dateDelta` to the start and end
        // - add `durationDelta` to the event's duration
        //
        // Returns a function that can be called to undo all the operations.
        //
        function mutateEvents(events, clearEnd, forceAllDay, dateDelta, durationDelta) {
            var isAmbigTimezone = t.getIsAmbigTimezone();
            var undoFunctions = [];

            $.each(events, function(i, event) {
                var oldAllDay = event._allDay;
                var oldStart = event._start;
                var oldEnd = event._end;
                var newAllDay = forceAllDay != null ? forceAllDay : oldAllDay;
                var newStart = oldStart.clone();
                var newEnd = (!clearEnd && oldEnd) ? oldEnd.clone() : null;

                // NOTE: this function is responsible for transforming `newStart` and `newEnd`,
                // which were initialized to the OLD values first. `newEnd` may be null.

                // normlize newStart/newEnd to be consistent with newAllDay
                if (newAllDay) {
                    newStart.stripTime();
                    if (newEnd) {
                        newEnd.stripTime();
                    }
                }
                else {
                    if (!newStart.hasTime()) {
                        newStart = t.rezoneDate(newStart);
                    }
                    if (newEnd && !newEnd.hasTime()) {
                        newEnd = t.rezoneDate(newEnd);
                    }
                }

                // ensure we have an end date if necessary
                if (!newEnd && (options.forceEventDuration || +durationDelta)) {
                    newEnd = t.getDefaultEventEnd(newAllDay, newStart);
                }

                // translate the dates
                newStart.add(dateDelta);
                if (newEnd) {
                    newEnd.add(dateDelta).add(durationDelta);
                }

                // if the dates have changed, and we know it is impossible to recompute the
                // timezone offsets, strip the zone.
                if (isAmbigTimezone) {
                    if (+dateDelta || +durationDelta) {
                        newStart.stripZone();
                        if (newEnd) {
                            newEnd.stripZone();
                        }
                    }
                }

                event.allDay = newAllDay;
                event.start = newStart;
                event.end = newEnd;
                backupEventDates(event);

                undoFunctions.push(function() {
                    event.allDay = oldAllDay;
                    event.start = oldStart;
                    event.end = oldEnd;
                    backupEventDates(event);
                });
            });

            return function() {
                for (var i=0; i<undoFunctions.length; i++) {
                    undoFunctions[i]();
                }
            };
        }

    }


// updates the "backup" properties, which are preserved in order to compute diffs later on.
    function backupEventDates(event) {
        event._allDay = event.allDay;
        event._start = event.start.clone();
        event._end = event.end ? event.end.clone() : null;
    }

    ;;

    fc.applyAll = applyAll;



// Create an object that has the given prototype.
// Just like Object.create
    function createObject(proto) {
        var f = function() {};
        f.prototype = proto;
        return new f();
    }

// Copies specifically-owned (non-protoype) properties of `b` onto `a`.
// FYI, $.extend would copy *all* properties of `b` onto `a`.
    function extend(a, b) {
        for (var i in b) {
            if (b.hasOwnProperty(i)) {
                a[i] = b[i];
            }
        }
    }



    /* Date
-----------------------------------------------------------------------------*/


    var dayIDs = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];


// diffs the two moments into a Duration where full-days are recorded first,
// then the remaining time.
    function dayishDiff(d1, d0) {
        return moment.duration({
            days: d1.clone().stripTime().diff(d0.clone().stripTime(), 'days'),
            ms: d1.time() - d0.time()
        });
    }


    function isNativeDate(input) {
        return  Object.prototype.toString.call(input) === '[object Date]' ||
            input instanceof Date;
    }



    /* Event Element Binding
-----------------------------------------------------------------------------*/


    function lazySegBind(container, segs, bindHandlers) {
        container.unbind('mouseover').mouseover(function(ev) {
            var parent=ev.target, e,
                i, seg;
            while (parent != this) {
                e = parent;
                parent = parent.parentNode;
            }
            if ((i = e._fci) !== undefined) {
                e._fci = undefined;
                seg = segs[i];
                bindHandlers(seg.event, seg.element, seg);
                $(ev.target).trigger(ev);
            }
            ev.stopPropagation();
        });
    }



    /* Element Dimensions
-----------------------------------------------------------------------------*/


    function setOuterWidth(element, width, includeMargins) {
        for (var i=0, e; i<element.length; i++) {
            e = $(element[i]);
            e.width(Math.max(0, width - hsides(e, includeMargins)));
        }
    }


    function setOuterHeight(element, height, includeMargins) {
        for (var i=0, e; i<element.length; i++) {
            e = $(element[i]);
            e.height(Math.max(0, height - vsides(e, includeMargins)));
        }
    }


    function hsides(element, includeMargins) {
        return hpadding(element) + hborders(element) + (includeMargins ? hmargins(element) : 0);
    }


    function hpadding(element) {
        return (parseFloat($.css(element[0], 'paddingLeft', true)) || 0) +
            (parseFloat($.css(element[0], 'paddingRight', true)) || 0);
    }


    function hmargins(element) {
        return (parseFloat($.css(element[0], 'marginLeft', true)) || 0) +
            (parseFloat($.css(element[0], 'marginRight', true)) || 0);
    }


    function hborders(element) {
        return (parseFloat($.css(element[0], 'borderLeftWidth', true)) || 0) +
            (parseFloat($.css(element[0], 'borderRightWidth', true)) || 0);
    }


    function vsides(element, includeMargins) {
        return vpadding(element) +  vborders(element) + (includeMargins ? vmargins(element) : 0);
    }


    function vpadding(element) {
        return (parseFloat($.css(element[0], 'paddingTop', true)) || 0) +
            (parseFloat($.css(element[0], 'paddingBottom', true)) || 0);
    }


    function vmargins(element) {
        return (parseFloat($.css(element[0], 'marginTop', true)) || 0) +
            (parseFloat($.css(element[0], 'marginBottom', true)) || 0);
    }


    function vborders(element) {
        return (parseFloat($.css(element[0], 'borderTopWidth', true)) || 0) +
            (parseFloat($.css(element[0], 'borderBottomWidth', true)) || 0);
    }



    /* Misc Utils
-----------------------------------------------------------------------------*/


//TODO: arraySlice
//TODO: isFunction, grep ?


    function noop() { }


    function dateCompare(a, b) { // works with moments too
        return a - b;
    }


    function arrayMax(a) {
        return Math.max.apply(Math, a);
    }


    function smartProperty(obj, name) { // get a camel-cased/namespaced property of an object
        obj = obj || {};
        if (obj[name] !== undefined) {
            return obj[name];
        }
        var parts = name.split(/(?=[A-Z])/),
            i=parts.length-1, res;
        for (; i>=0; i--) {
            res = obj[parts[i].toLowerCase()];
            if (res !== undefined) {
                return res;
            }
        }
        return obj['default'];
    }


    function htmlEscape(s) {
        return (s + '').replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#039;')
            .replace(/"/g, '&quot;')
            .replace(/\n/g, '<br />');
    }


    function stripHTMLEntities(text) {
        return text.replace(/&.*?;/g, '');
    }


    function disableTextSelection(element) {
        element
            .attr('unselectable', 'on')
            .css('MozUserSelect', 'none')
            .bind('selectstart.ui', function() { return false; });
    }


    /*
function enableTextSelection(element) {
	element
		.attr('unselectable', 'off')
		.css('MozUserSelect', '')
		.unbind('selectstart.ui');
}
*/


    function markFirstLast(e) { // TODO: use CSS selectors instead
        e.children()
            .removeClass('fc-first fc-last')
            .filter(':first-child')
            .addClass('fc-first')
            .end()
            .filter(':last-child')
            .addClass('fc-last');
    }


    function getSkinCss(event, opt) {
        var source = event.source || {};
        var eventColor = event.color;
        var sourceColor = source.color;
        var optionColor = opt('eventColor');
        var backgroundColor =
            event.backgroundColor ||
            eventColor ||
            source.backgroundColor ||
            sourceColor ||
            opt('eventBackgroundColor') ||
            optionColor;
        var borderColor =
            event.borderColor ||
            eventColor ||
            source.borderColor ||
            sourceColor ||
            opt('eventBorderColor') ||
            optionColor;
        var textColor =
            event.textColor ||
            source.textColor ||
            opt('eventTextColor');
        var statements = [];
        if (backgroundColor) {
            statements.push('background-color:' + backgroundColor);
        }
        if (borderColor) {
            statements.push('border-color:' + borderColor);
        }
        if (textColor) {
            statements.push('color:' + textColor);
        }
        return statements.join(';');
    }


    function applyAll(functions, thisObj, args) {
        if ($.isFunction(functions)) {
            functions = [ functions ];
        }
        if (functions) {
            var i;
            var ret;
            for (i=0; i<functions.length; i++) {
                ret = functions[i].apply(thisObj, args) || ret;
            }
            return ret;
        }
    }


    function firstDefined() {
        for (var i=0; i<arguments.length; i++) {
            if (arguments[i] !== undefined) {
                return arguments[i];
            }
        }
    }


    ;;

    var ambigDateOfMonthRegex = /^\s*\d{4}-\d\d$/;
    var ambigTimeOrZoneRegex = /^\s*\d{4}-(?:(\d\d-\d\d)|(W\d\d$)|(W\d\d-\d)|(\d\d\d))((T| )(\d\d(:\d\d(:\d\d(\.\d+)?)?)?)?)?$/;


// Creating
// -------------------------------------------------------------------------------------------------

// Creates a new moment, similar to the vanilla moment(...) constructor, but with
// extra features (ambiguous time, enhanced formatting). When gived an existing moment,
// it will function as a clone (and retain the zone of the moment). Anything else will
// result in a moment in the local zone.
    fc.moment = function() {
        return makeMoment(arguments);
    };

// Sames as fc.moment, but forces the resulting moment to be in the UTC timezone.
    fc.moment.utc = function() {
        var mom = makeMoment(arguments, true);

        // Force it into UTC because makeMoment doesn't guarantee it.
        if (mom.hasTime()) { // don't give ambiguously-timed moments a UTC zone
            mom.utc();
        }

        return mom;
    };

// Same as fc.moment, but when given an ISO8601 string, the timezone offset is preserved.
// ISO8601 strings with no timezone offset will become ambiguously zoned.
    fc.moment.parseZone = function() {
        return makeMoment(arguments, true, true);
    };

// Builds an FCMoment from args. When given an existing moment, it clones. When given a native
// Date, or called with no arguments (the current time), the resulting moment will be local.
// Anything else needs to be "parsed" (a string or an array), and will be affected by:
//    parseAsUTC - if there is no zone information, should we parse the input in UTC?
//    parseZone - if there is zone information, should we force the zone of the moment?
    function makeMoment(args, parseAsUTC, parseZone) {
        var input = args[0];
        var isSingleString = args.length == 1 && typeof input === 'string';
        var isAmbigTime;
        var isAmbigZone;
        var ambigMatch;
        var output; // an object with fields for the new FCMoment object

        if (moment.isMoment(input)) {
            output = moment.apply(null, args); // clone it

            // the ambig properties have not been preserved in the clone, so reassign them
            if (input._ambigTime) {
                output._ambigTime = true;
            }
            if (input._ambigZone) {
                output._ambigZone = true;
            }
        }
        else if (isNativeDate(input) || input === undefined) {
            output = moment.apply(null, args); // will be local
        }
        else { // "parsing" is required
            isAmbigTime = false;
            isAmbigZone = false;

            if (isSingleString) {
                if (ambigDateOfMonthRegex.test(input)) {
                    // accept strings like '2014-05', but convert to the first of the month
                    input += '-01';
                    args = [ input ]; // for when we pass it on to moment's constructor
                    isAmbigTime = true;
                    isAmbigZone = true;
                }
                else if ((ambigMatch = ambigTimeOrZoneRegex.exec(input))) {
                    isAmbigTime = !ambigMatch[5]; // no time part?
                    isAmbigZone = true;
                }
            }
            else if ($.isArray(input)) {
                // arrays have no timezone information, so assume ambiguous zone
                isAmbigZone = true;
            }
            // otherwise, probably a string with a format

            if (parseAsUTC) {
                output = moment.utc.apply(moment, args);
            }
            else {
                output = moment.apply(null, args);
            }

            if (isAmbigTime) {
                output._ambigTime = true;
                output._ambigZone = true; // ambiguous time always means ambiguous zone
            }
            else if (parseZone) { // let's record the inputted zone somehow
                if (isAmbigZone) {
                    output._ambigZone = true;
                }
                else if (isSingleString) {
                    output.zone(input); // if not a valid zone, will assign UTC
                }
            }
        }

        return new FCMoment(output);
    }

// Our subclass of Moment.
// Accepts an object with the internal Moment properties that should be copied over to
// `this` object (most likely another Moment object). The values in this data must not
// be referenced by anything else (two moments sharing a Date object for example).
    function FCMoment(internalData) {
        extend(this, internalData);
    }

// Chain the prototype to Moment's
    FCMoment.prototype = createObject(moment.fn);

// We need this because Moment's implementation won't create an FCMoment,
// nor will it copy over the ambig flags.
    FCMoment.prototype.clone = function() {
        return makeMoment([ this ]);
    };


// Time-of-day
// -------------------------------------------------------------------------------------------------

// GETTER
// Returns a Duration with the hours/minutes/seconds/ms values of the moment.
// If the moment has an ambiguous time, a duration of 00:00 will be returned.
//
// SETTER
// You can supply a Duration, a Moment, or a Duration-like argument.
// When setting the time, and the moment has an ambiguous time, it then becomes unambiguous.
    FCMoment.prototype.time = function(time) {
        if (time == null) { // getter
            return moment.duration({
                hours: this.hours(),
                minutes: this.minutes(),
                seconds: this.seconds(),
                milliseconds: this.milliseconds()
            });
        }
        else { // setter

            delete this._ambigTime; // mark that the moment now has a time

            if (!moment.isDuration(time) && !moment.isMoment(time)) {
                time = moment.duration(time);
            }

            // The day value should cause overflow (so 24 hours becomes 00:00:00 of next day).
            // Only for Duration times, not Moment times.
            var dayHours = 0;
            if (moment.isDuration(time)) {
                dayHours = Math.floor(time.asDays()) * 24;
            }

            // We need to set the individual fields.
            // Can't use startOf('day') then add duration. In case of DST at start of day.
            return this.hours(dayHours + time.hours())
                .minutes(time.minutes())
                .seconds(time.seconds())
                .milliseconds(time.milliseconds());
        }
    };

// Converts the moment to UTC, stripping out its time-of-day and timezone offset,
// but preserving its YMD. A moment with a stripped time will display no time
// nor timezone offset when .format() is called.
    FCMoment.prototype.stripTime = function() {
        var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array

        // set the internal UTC flag
        moment.fn.utc.call(this); // call the original method, because we don't want to affect _ambigZone

        this.year(a[0]) // TODO: find a way to do this in one shot
            .month(a[1])
            .date(a[2])
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);

        // Mark the time as ambiguous. This needs to happen after the .utc() call, which calls .zone(), which
        // clears all ambig flags. Same concept with the .year/month/date calls in the case of moment-timezone.
        this._ambigTime = true;
        this._ambigZone = true; // if ambiguous time, also ambiguous timezone offset

        return this; // for chaining
    };

// Returns if the moment has a non-ambiguous time (boolean)
    FCMoment.prototype.hasTime = function() {
        return !this._ambigTime;
    };


// Timezone
// -------------------------------------------------------------------------------------------------

// Converts the moment to UTC, stripping out its timezone offset, but preserving its
// YMD and time-of-day. A moment with a stripped timezone offset will display no
// timezone offset when .format() is called.
    FCMoment.prototype.stripZone = function() {
        var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array
        var wasAmbigTime = this._ambigTime;

        moment.fn.utc.call(this); // set the internal UTC flag

        this.year(a[0]) // TODO: find a way to do this in one shot
            .month(a[1])
            .date(a[2])
            .hours(a[3])
            .minutes(a[4])
            .seconds(a[5])
            .milliseconds(a[6]);

        if (wasAmbigTime) {
            // the above call to .utc()/.zone() unfortunately clears the ambig flags, so reassign
            this._ambigTime = true;
        }

        // Mark the zone as ambiguous. This needs to happen after the .utc() call, which calls .zone(), which
        // clears all ambig flags. Same concept with the .year/month/date calls in the case of moment-timezone.
        this._ambigZone = true;

        return this; // for chaining
    };

// Returns of the moment has a non-ambiguous timezone offset (boolean)
    FCMoment.prototype.hasZone = function() {
        return !this._ambigZone;
    };

// this method implicitly marks a zone
    FCMoment.prototype.zone = function(tzo) {

        if (tzo != null) {
            // FYI, the delete statements need to be before the .zone() call or else chaos ensues
            // for reasons I don't understand.
            delete this._ambigTime;
            delete this._ambigZone;
        }

        return moment.fn.zone.apply(this, arguments);
    };

// this method implicitly marks a zone
    FCMoment.prototype.local = function() {
        var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array
        var wasAmbigZone = this._ambigZone;

        // will happen anyway via .local()/.zone(), but don't want to rely on internal implementation
        delete this._ambigTime;
        delete this._ambigZone;

        moment.fn.local.apply(this, arguments);

        if (wasAmbigZone) {
            // If the moment was ambiguously zoned, the date fields were stored as UTC.
            // We want to preserve these, but in local time.
            this.year(a[0]) // TODO: find a way to do this in one shot
                .month(a[1])
                .date(a[2])
                .hours(a[3])
                .minutes(a[4])
                .seconds(a[5])
                .milliseconds(a[6]);
        }

        return this; // for chaining
    };

// this method implicitly marks a zone
    FCMoment.prototype.utc = function() {

        // will happen anyway via .local()/.zone(), but don't want to rely on internal implementation
        delete this._ambigTime;
        delete this._ambigZone;

        return moment.fn.utc.apply(this, arguments);
    };


// Formatting
// -------------------------------------------------------------------------------------------------

    FCMoment.prototype.format = function() {
        if (arguments[0]) {
            return formatDate(this, arguments[0]); // our extended formatting
        }
        if (this._ambigTime) {
            return momentFormat(this, 'YYYY-MM-DD');
        }
        if (this._ambigZone) {
            return momentFormat(this, 'YYYY-MM-DD[T]HH:mm:ss');
        }
        return momentFormat(this); // default moment original formatting
    };

    FCMoment.prototype.toISOString = function() {
        if (this._ambigTime) {
            return momentFormat(this, 'YYYY-MM-DD');
        }
        if (this._ambigZone) {
            return momentFormat(this, 'YYYY-MM-DD[T]HH:mm:ss');
        }
        return moment.fn.toISOString.apply(this, arguments);
    };


// Querying
// -------------------------------------------------------------------------------------------------

// Is the moment within the specified range? `end` is exclusive.
    FCMoment.prototype.isWithin = function(start, end) {
        var a = commonlyAmbiguate([ this, start, end ]);
        return a[0] >= a[1] && a[0] < a[2];
    };

// Make these query methods work with ambiguous moments
    $.each([
        'isBefore',
        'isAfter',
        'isSame'
    ], function(i, methodName) {
        FCMoment.prototype[methodName] = function(input, units) {
            var a = commonlyAmbiguate([ this, input ]);
            return moment.fn[methodName].call(a[0], a[1], units);
        };
    });


// Misc Internals
// -------------------------------------------------------------------------------------------------

// given an array of moment-like inputs, return a parallel array w/ moments similarly ambiguated.
// for example, of one moment has ambig time, but not others, all moments will have their time stripped.
    function commonlyAmbiguate(inputs) {
        var outputs = [];
        var anyAmbigTime = false;
        var anyAmbigZone = false;
        var i;

        for (i=0; i<inputs.length; i++) {
            outputs.push(fc.moment(inputs[i]));
            anyAmbigTime = anyAmbigTime || outputs[i]._ambigTime;
            anyAmbigZone = anyAmbigZone || outputs[i]._ambigZone;
        }

        for (i=0; i<outputs.length; i++) {
            if (anyAmbigTime) {
                outputs[i].stripTime();
            }
            else if (anyAmbigZone) {
                outputs[i].stripZone();
            }
        }

        return outputs;
    }

    ;;

// Single Date Formatting
// -------------------------------------------------------------------------------------------------


// call this if you want Moment's original format method to be used
    function momentFormat(mom, formatStr) {
        return moment.fn.format.call(mom, formatStr);
    }


// Formats `date` with a Moment formatting string, but allow our non-zero areas and
// additional token.
    function formatDate(date, formatStr) {
        return formatDateWithChunks(date, getFormatStringChunks(formatStr));
    }


    function formatDateWithChunks(date, chunks) {
        var s = '';
        var i;

        for (i=0; i<chunks.length; i++) {
            s += formatDateWithChunk(date, chunks[i]);
        }

        return s;
    }


// addition formatting tokens we want recognized
    var tokenOverrides = {
        t: function(date) { // "a" or "p"
            return momentFormat(date, 'a').charAt(0);
        },
        T: function(date) { // "A" or "P"
            return momentFormat(date, 'A').charAt(0);
        }
    };


    function formatDateWithChunk(date, chunk) {
        var token;
        var maybeStr;

        if (typeof chunk === 'string') { // a literal string
            return chunk;
        }
        else if ((token = chunk.token)) { // a token, like "YYYY"
            if (tokenOverrides[token]) {
                return tokenOverrides[token](date); // use our custom token
            }
            return momentFormat(date, token);
        }
        else if (chunk.maybe) { // a grouping of other chunks that must be non-zero
            maybeStr = formatDateWithChunks(date, chunk.maybe);
            if (maybeStr.match(/[1-9]/)) {
                return maybeStr;
            }
        }

        return '';
    }


// Date Range Formatting
// -------------------------------------------------------------------------------------------------
// TODO: make it work with timezone offset

// Using a formatting string meant for a single date, generate a range string, like
// "Sep 2 - 9 2013", that intelligently inserts a separator where the dates differ.
// If the dates are the same as far as the format string is concerned, just return a single
// rendering of one date, without any separator.
    function formatRange(date1, date2, formatStr, separator, isRTL) {

        date1 = fc.moment.parseZone(date1);
        date2 = fc.moment.parseZone(date2);

        // Expand localized format strings, like "LL" -> "MMMM D YYYY"
        formatStr = date1.lang().longDateFormat(formatStr) || formatStr;
        // BTW, this is not important for `formatDate` because it is impossible to put custom tokens
        // or non-zero areas in Moment's localized format strings.

        separator = separator || ' - ';

        return formatRangeWithChunks(
            date1,
            date2,
            getFormatStringChunks(formatStr),
            separator,
            isRTL
        );
    }
    fc.formatRange = formatRange; // expose


    function formatRangeWithChunks(date1, date2, chunks, separator, isRTL) {
        var chunkStr; // the rendering of the chunk
        var leftI;
        var leftStr = '';
        var rightI;
        var rightStr = '';
        var middleI;
        var middleStr1 = '';
        var middleStr2 = '';
        var middleStr = '';

        // Start at the leftmost side of the formatting string and continue until you hit a token
        // that is not the same between dates.
        for (leftI=0; leftI<chunks.length; leftI++) {
            chunkStr = formatSimilarChunk(date1, date2, chunks[leftI]);
            if (chunkStr === false) {
                break;
            }
            leftStr += chunkStr;
        }

        // Similarly, start at the rightmost side of the formatting string and move left
        for (rightI=chunks.length-1; rightI>leftI; rightI--) {
            chunkStr = formatSimilarChunk(date1, date2, chunks[rightI]);
            if (chunkStr === false) {
                break;
            }
            rightStr = chunkStr + rightStr;
        }

        // The area in the middle is different for both of the dates.
        // Collect them distinctly so we can jam them together later.
        for (middleI=leftI; middleI<=rightI; middleI++) {
            middleStr1 += formatDateWithChunk(date1, chunks[middleI]);
            middleStr2 += formatDateWithChunk(date2, chunks[middleI]);
        }

        if (middleStr1 || middleStr2) {
            if (isRTL) {
                middleStr = middleStr2 + separator + middleStr1;
            }
            else {
                middleStr = middleStr1 + separator + middleStr2;
            }
        }

        return leftStr + middleStr + rightStr;
    }


    var similarUnitMap = {
        Y: 'year',
        M: 'month',
        D: 'day', // day of month
        d: 'day', // day of week
        // prevents a separator between anything time-related...
        A: 'second', // AM/PM
        a: 'second', // am/pm
        T: 'second', // A/P
        t: 'second', // a/p
        H: 'second', // hour (24)
        h: 'second', // hour (12)
        m: 'second', // minute
        s: 'second' // second
    };
// TODO: week maybe?


// Given a formatting chunk, and given that both dates are similar in the regard the
// formatting chunk is concerned, format date1 against `chunk`. Otherwise, return `false`.
    function formatSimilarChunk(date1, date2, chunk) {
        var token;
        var unit;

        if (typeof chunk === 'string') { // a literal string
            return chunk;
        }
        else if ((token = chunk.token)) {
            unit = similarUnitMap[token.charAt(0)];
            // are the dates the same for this unit of measurement?
            if (unit && date1.isSame(date2, unit)) {
                return momentFormat(date1, token); // would be the same if we used `date2`
                // BTW, don't support custom tokens
            }
        }

        return false; // the chunk is NOT the same for the two dates
        // BTW, don't support splitting on non-zero areas
    }


// Chunking Utils
// -------------------------------------------------------------------------------------------------


    var formatStringChunkCache = {};


    function getFormatStringChunks(formatStr) {
        if (formatStr in formatStringChunkCache) {
            return formatStringChunkCache[formatStr];
        }
        return (formatStringChunkCache[formatStr] = chunkFormatString(formatStr));
    }


// Break the formatting string into an array of chunks
    function chunkFormatString(formatStr) {
        var chunks = [];
        var chunker = /\[([^\]]*)\]|\(([^\)]*)\)|(LT|(\w)\4*o?)|([^\w\[\(]+)/g; // TODO: more descrimination
        var match;

        while ((match = chunker.exec(formatStr))) {
            if (match[1]) { // a literal string inside [ ... ]
                chunks.push(match[1]);
            }
            else if (match[2]) { // non-zero formatting inside ( ... )
                chunks.push({ maybe: chunkFormatString(match[2]) });
            }
            else if (match[3]) { // a formatting token
                chunks.push({ token: match[3] });
            }
            else if (match[5]) { // an unenclosed literal string
                chunks.push(match[5]);
            }
        }

        return chunks;
    }

    ;;

    fcViews.month = MonthView;

    function MonthView(element, calendar) {
        var t = this;


        // exports
        t.incrementDate = incrementDate;
        t.render = render;


        // imports
        BasicView.call(t, element, calendar, 'month');


        function incrementDate(date, delta) {
            return date.clone().stripTime().add('months', delta).startOf('month');
        }


        function render(date) {

            t.intervalStart = date.clone().stripTime().startOf('month');
            t.intervalEnd = t.intervalStart.clone().add('months', 1);

            t.start = t.intervalStart.clone();
            t.start = t.skipHiddenDays(t.start); // move past the first week if no visible days
            t.start.startOf('week');
            t.start = t.skipHiddenDays(t.start); // move past the first invisible days of the week

            t.end = t.intervalEnd.clone();
            t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last week if no visible days
            t.end.add('days', (7 - t.end.weekday()) % 7); // move to end of week if not already
            t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last invisible days of the week

            var rowCnt = Math.ceil( // need to ceil in case there are hidden days
                t.end.diff(t.start, 'weeks', true) // returnfloat=true
            );
            if (t.opt('weekMode') == 'fixed') {
                t.end.add('weeks', 6 - rowCnt);
                rowCnt = 6;
            }

            t.title = calendar.formatDate(t.intervalStart, t.opt('titleFormat'));

            t.renderBasic(rowCnt, t.getCellsPerWeek(), true);
        }


    }

    ;;

    fcViews.basicWeek = BasicWeekView;

    function BasicWeekView(element, calendar) { // TODO: do a WeekView mixin
        var t = this;


        // exports
        t.incrementDate = incrementDate;
        t.render = render;


        // imports
        BasicView.call(t, element, calendar, 'basicWeek');


        function incrementDate(date, delta) {
            return date.clone().stripTime().add('weeks', delta).startOf('week');
        }


        function render(date) {

            t.intervalStart = date.clone().stripTime().startOf('week');
            t.intervalEnd = t.intervalStart.clone().add('weeks', 1);

            t.start = t.skipHiddenDays(t.intervalStart);
            t.end = t.skipHiddenDays(t.intervalEnd, -1, true);

            t.title = calendar.formatRange(
                t.start,
                t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
                t.opt('titleFormat'),
                ' \u2014 ' // emphasized dash
            );

            t.renderBasic(1, t.getCellsPerWeek(), false);
        }


    }

    ;;

    fcViews.basicDay = BasicDayView;

    function BasicDayView(element, calendar) { // TODO: make a DayView mixin
        var t = this;


        // exports
        t.incrementDate = incrementDate;
        t.render = render;


        // imports
        BasicView.call(t, element, calendar, 'basicDay');


        function incrementDate(date, delta) {
            var out = date.clone().stripTime().add('days', delta);
            out = t.skipHiddenDays(out, delta < 0 ? -1 : 1);
            return out;
        }


        function render(date) {

            t.start = t.intervalStart = date.clone().stripTime();
            t.end = t.intervalEnd = t.start.clone().add('days', 1);

            t.title = calendar.formatDate(t.start, t.opt('titleFormat'));

            t.renderBasic(1, 1, false);
        }


    }

    ;;

    setDefaults({
        weekMode: 'fixed'
    });


    function BasicView(element, calendar, viewName) {
        var t = this;


        // exports
        t.renderBasic = renderBasic;
        t.setHeight = setHeight;
        t.setWidth = setWidth;
        t.renderDayOverlay = renderDayOverlay;
        t.defaultSelectionEnd = defaultSelectionEnd;
        t.renderSelection = renderSelection;
        t.clearSelection = clearSelection;
        t.reportDayClick = reportDayClick; // for selection (kinda hacky)
        t.dragStart = dragStart;
        t.dragStop = dragStop;
        t.getHoverListener = function() { return hoverListener; };
        t.colLeft = colLeft;
        t.colRight = colRight;
        t.colContentLeft = colContentLeft;
        t.colContentRight = colContentRight;
        t.getIsCellAllDay = function() { return true; };
        t.allDayRow = allDayRow;
        t.getRowCnt = function() { return rowCnt; };
        t.getColCnt = function() { return colCnt; };
        t.getColWidth = function() { return colWidth; };
        t.getDaySegmentContainer = function() { return daySegmentContainer; };


        // imports
        View.call(t, element, calendar, viewName);
        OverlayManager.call(t);
        SelectionManager.call(t);
        BasicEventRenderer.call(t);
        var opt = t.opt;
        var trigger = t.trigger;
        var renderOverlay = t.renderOverlay;
        var clearOverlays = t.clearOverlays;
        var daySelectionMousedown = t.daySelectionMousedown;
        var cellToDate = t.cellToDate;
        var dateToCell = t.dateToCell;
        var rangeToSegments = t.rangeToSegments;
        var formatDate = calendar.formatDate;
        var calculateWeekNumber = calendar.calculateWeekNumber;


        // locals

        var table;
        var head;
        var headCells;
        var body;
        var bodyRows;
        var bodyCells;
        var bodyFirstCells;
        var firstRowCellInners;
        var firstRowCellContentInners;
        var daySegmentContainer;

        var viewWidth;
        var viewHeight;
        var colWidth;
        var weekNumberWidth;

        var rowCnt, colCnt;
        var showNumbers;
        var coordinateGrid;
        var hoverListener;
        var colPositions;
        var colContentPositions;

        var tm;
        var colFormat;
        var showWeekNumbers;



        /* Rendering
	------------------------------------------------------------*/


        disableTextSelection(element.addClass('fc-grid'));


        function renderBasic(_rowCnt, _colCnt, _showNumbers) {
            rowCnt = _rowCnt;
            colCnt = _colCnt;
            showNumbers = _showNumbers;
            updateOptions();

            if (!body) {
                buildEventContainer();
            }

            buildTable();
        }


        function updateOptions() {
            tm = opt('theme') ? 'ui' : 'fc';
            colFormat = opt('columnFormat');
            showWeekNumbers = opt('weekNumbers');
        }


        function buildEventContainer() {
            daySegmentContainer =
                $("<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>")
                    .appendTo(element);
        }


        function buildTable() {
            var html = buildTableHTML();

            if (table) {
                table.remove();
            }
            table = $(html).appendTo(element);

            head = table.find('thead');
            headCells = head.find('.fc-day-header');
            body = table.find('tbody');
            bodyRows = body.find('tr');
            bodyCells = body.find('.fc-day');
            bodyFirstCells = bodyRows.find('td:first-child');

            firstRowCellInners = bodyRows.eq(0).find('.fc-day > div');
            firstRowCellContentInners = bodyRows.eq(0).find('.fc-day-content > div');

            markFirstLast(head.add(head.find('tr'))); // marks first+last tr/th's
            markFirstLast(bodyRows); // marks first+last td's
            bodyRows.eq(0).addClass('fc-first');
            bodyRows.filter(':last').addClass('fc-last');

            bodyCells.each(function(i, _cell) {
                var date = cellToDate(
                    Math.floor(i / colCnt),
                    i % colCnt
                );
                trigger('dayRender', t, date, $(_cell));
            });

            dayBind(bodyCells);
        }



        /* HTML Building
	-----------------------------------------------------------*/


        function buildTableHTML() {
            var html =
                "<table class='fc-border-separate' style='width:100%' cellspacing='0'>" +
                buildHeadHTML() +
                buildBodyHTML() +
                "</table>";

            return html;
        }


        function buildHeadHTML() {
            var headerClass = tm + "-widget-header";
            var html = '';
            var col;
            var date;

            html += "<thead><tr>";

            if (showWeekNumbers) {
                html +=
                    "<th class='fc-week-number " + headerClass + "'>" +
                    htmlEscape(opt('weekNumberTitle')) +
                    "</th>";
            }

            for (col=0; col<colCnt; col++) {
                date = cellToDate(0, col);
                html +=
                    "<th class='fc-day-header fc-" + dayIDs[date.day()] + " " + headerClass + "'>" +
                    htmlEscape(formatDate(date, colFormat)) +
                    "</th>";
            }

            html += "</tr></thead>";

            return html;
        }


        function buildBodyHTML() {
            var contentClass = tm + "-widget-content";
            var html = '';
            var row;
            var col;
            var date;

            html += "<tbody>";

            for (row=0; row<rowCnt; row++) {

                html += "<tr class='fc-week'>";

                if (showWeekNumbers) {
                    date = cellToDate(row, 0);
                    html +=
                        "<td class='fc-week-number " + contentClass + "'>" +
                        "<div>" +
                        htmlEscape(calculateWeekNumber(date)) +
                        "</div>" +
                        "</td>";
                }

                for (col=0; col<colCnt; col++) {
                    date = cellToDate(row, col);
                    html += buildCellHTML(date);
                }

                html += "</tr>";
            }

            html += "</tbody>";

            return html;
        }


        function buildCellHTML(date) { // date assumed to have stripped time
            var month = t.intervalStart.month();
            var today = calendar.getNow().stripTime();
            var html = '';
            var contentClass = tm + "-widget-content";
            var classNames = [
                'fc-day',
                'fc-' + dayIDs[date.day()],
                contentClass
            ];

            if (date.month() != month) {
                classNames.push('fc-other-month');
            }
            if (date.isSame(today, 'day')) {
                classNames.push(
                    'fc-today',
                    tm + '-state-highlight'
                );
            }
            else if (date < today) {
                classNames.push('fc-past');
            }
            else {
                classNames.push('fc-future');
            }

            html +=
                "<td" +
                " class='" + classNames.join(' ') + "'" +
                " data-date='" + date.format() + "'" +
                ">" +
                "<div>";

            if (showNumbers) {
                html += "<div class='fc-day-number'>" + date.date() + "</div>";
            }

            html +=
                "<div class='fc-day-content'>" +
                "<div style='position:relative'>&nbsp;</div>" +
                "</div>" +
                "</div>" +
                "</td>";

            return html;
        }



        /* Dimensions
	-----------------------------------------------------------*/


        function setHeight(height) {
            viewHeight = height;

            var bodyHeight = Math.max(viewHeight - head.height(), 0);
            var rowHeight;
            var rowHeightLast;
            var cell;

            if (opt('weekMode') == 'variable') {
                rowHeight = rowHeightLast = Math.floor(bodyHeight / (rowCnt==1 ? 2 : 6));
            }else{
                rowHeight = Math.floor(bodyHeight / rowCnt);
                rowHeightLast = bodyHeight - rowHeight * (rowCnt-1);
            }

            bodyFirstCells.each(function(i, _cell) {
                if (i < rowCnt) {
                    cell = $(_cell);
                    cell.find('> div').css(
                        'min-height',
                        (i==rowCnt-1 ? rowHeightLast : rowHeight) - vsides(cell)
                    );
                }
            });

        }


        function setWidth(width) {
            viewWidth = width;
            colPositions.clear();
            colContentPositions.clear();

            weekNumberWidth = 0;
            if (showWeekNumbers) {
                weekNumberWidth = head.find('th.fc-week-number').outerWidth();
            }

            colWidth = Math.floor((viewWidth - weekNumberWidth) / colCnt);
            setOuterWidth(headCells.slice(0, -1), colWidth);
        }



        /* Day clicking and binding
	-----------------------------------------------------------*/


        function dayBind(days) {
            days.click(dayClick)
                .mousedown(daySelectionMousedown);
        }


        function dayClick(ev) {
            if (!opt('selectable')) { // if selectable, SelectionManager will worry about dayClick
                var date = calendar.moment($(this).data('date'));
                trigger('dayClick', this, date, ev);
            }
        }



        /* Semi-transparent Overlay Helpers
	------------------------------------------------------*/
        // TODO: should be consolidated with AgendaView's methods


        function renderDayOverlay(overlayStart, overlayEnd, refreshCoordinateGrid) { // overlayEnd is exclusive

            if (refreshCoordinateGrid) {
                coordinateGrid.build();
            }

            var segments = rangeToSegments(overlayStart, overlayEnd);

            for (var i=0; i<segments.length; i++) {
                var segment = segments[i];
                dayBind(
                    renderCellOverlay(
                        segment.row,
                        segment.leftCol,
                        segment.row,
                        segment.rightCol
                    )
                );
            }
        }


        function renderCellOverlay(row0, col0, row1, col1) { // row1,col1 is inclusive
            var rect = coordinateGrid.rect(row0, col0, row1, col1, element);
            return renderOverlay(rect, element);
        }



        /* Selection
	-----------------------------------------------------------------------*/


        function defaultSelectionEnd(start) {
            return start.clone().stripTime().add('days', 1);
        }


        function renderSelection(start, end) { // end is exclusive
            renderDayOverlay(start, end, true); // true = rebuild every time
        }


        function clearSelection() {
            clearOverlays();
        }


        function reportDayClick(date, ev) {
            var cell = dateToCell(date);
            var _element = bodyCells[cell.row*colCnt + cell.col];
            trigger('dayClick', _element, date, ev);
        }



        /* External Dragging
	-----------------------------------------------------------------------*/


        function dragStart(_dragElement, ev, ui) {
            hoverListener.start(function(cell) {
                clearOverlays();
                if (cell) {
                    var d1 = cellToDate(cell);
                    var d2 = d1.clone().add(calendar.defaultAllDayEventDuration);
                    renderDayOverlay(d1, d2);
                }
            }, ev);
        }


        function dragStop(_dragElement, ev, ui) {
            var cell = hoverListener.stop();
            clearOverlays();
            if (cell) {
                trigger(
                    'drop',
                    _dragElement,
                    cellToDate(cell),
                    ev,
                    ui
                );
            }
        }



        /* Utilities
	--------------------------------------------------------*/


        coordinateGrid = new CoordinateGrid(function(rows, cols) {
            var e, n, p;
            headCells.each(function(i, _e) {
                e = $(_e);
                n = e.offset().left;
                if (i) {
                    p[1] = n;
                }
                p = [n];
                cols[i] = p;
            });
            p[1] = n + e.outerWidth();
            bodyRows.each(function(i, _e) {
                if (i < rowCnt) {
                    e = $(_e);
                    n = e.offset().top;
                    if (i) {
                        p[1] = n;
                    }
                    p = [n];
                    rows[i] = p;
                }
            });
            p[1] = n + e.outerHeight();
        });


        hoverListener = new HoverListener(coordinateGrid);

        colPositions = new HorizontalPositionCache(function(col) {
            return firstRowCellInners.eq(col);
        });

        colContentPositions = new HorizontalPositionCache(function(col) {
            return firstRowCellContentInners.eq(col);
        });


        function colLeft(col) {
            return colPositions.left(col);
        }


        function colRight(col) {
            return colPositions.right(col);
        }


        function colContentLeft(col) {
            return colContentPositions.left(col);
        }


        function colContentRight(col) {
            return colContentPositions.right(col);
        }


        function allDayRow(i) {
            return bodyRows.eq(i);
        }

    }

    ;;

    function BasicEventRenderer() {
        var t = this;


        // exports
        t.renderEvents = renderEvents;
        t.clearEvents = clearEvents;


        // imports
        DayEventRenderer.call(t);


        function renderEvents(events, modifiedEventId) {
            t.renderDayEvents(events, modifiedEventId);
        }


        function clearEvents() {
            t.getDaySegmentContainer().empty();
        }


        // TODO: have this class (and AgendaEventRenderer) be responsible for creating the event container div

    }

    ;;

    fcViews.agendaWeek = AgendaWeekView;

    function AgendaWeekView(element, calendar) { // TODO: do a WeekView mixin
        var t = this;


        // exports
        t.incrementDate = incrementDate;
        t.render = render;


        // imports
        AgendaView.call(t, element, calendar, 'agendaWeek');


        function incrementDate(date, delta) {
            return date.clone().stripTime().add('weeks', delta).startOf('week');
        }


        function render(date) {

            t.intervalStart = date.clone().stripTime().startOf('week');
            t.intervalEnd = t.intervalStart.clone().add('weeks', 1);

            t.start = t.skipHiddenDays(t.intervalStart);
            t.end = t.skipHiddenDays(t.intervalEnd, -1, true);

            t.title = calendar.formatRange(
                t.start,
                t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
                t.opt('titleFormat'),
                ' \u2014 ' // emphasized dash
            );

            t.renderAgenda(t.getCellsPerWeek());
        }


    }

    ;;

    fcViews.agendaDay = AgendaDayView;

    function AgendaDayView(element, calendar) { // TODO: make a DayView mixin
        var t = this;


        // exports
        t.incrementDate = incrementDate;
        t.render = render;


        // imports
        AgendaView.call(t, element, calendar, 'agendaDay');


        function incrementDate(date, delta) {
            var out = date.clone().stripTime().add('days', delta);
            out = t.skipHiddenDays(out, delta < 0 ? -1 : 1);
            return out;
        }


        function render(date) {

            t.start = t.intervalStart = date.clone().stripTime();
            t.end = t.intervalEnd = t.start.clone().add('days', 1);

            t.title = calendar.formatDate(t.start, t.opt('titleFormat'));

            t.renderAgenda(1);
        }


    }

    ;;

    setDefaults({
        allDaySlot: true,
        allDayText: 'all-day',

        scrollTime: '06:00:00',

        slotDuration: '00:30:00',

        axisFormat: generateAgendaAxisFormat,
        timeFormat: {
            agenda: generateAgendaTimeFormat
        },

        dragOpacity: {
            agenda: .5
        },
        minTime: '00:00:00',
        maxTime: '24:00:00',
        slotEventOverlap: true
    });


    function generateAgendaAxisFormat(options, langData) {
        return langData.longDateFormat('LT')
            .replace(':mm', '(:mm)')
            .replace(/(\Wmm)$/, '($1)') // like above, but for foreign langs
            .replace(/\s*a$/i, 'a'); // convert AM/PM/am/pm to lowercase. remove any spaces beforehand
    }


    function generateAgendaTimeFormat(options, langData) {
        return langData.longDateFormat('LT')
            .replace(/\s*a$/i, ''); // remove trailing AM/PM
    }


// TODO: make it work in quirks mode (event corners, all-day height)
// TODO: test liquid width, especially in IE6


    function AgendaView(element, calendar, viewName) {
        var t = this;


        // exports
        t.renderAgenda = renderAgenda;
        t.setWidth = setWidth;
        t.setHeight = setHeight;
        t.afterRender = afterRender;
        t.computeDateTop = computeDateTop;
        t.getIsCellAllDay = getIsCellAllDay;
        t.allDayRow = function() { return allDayRow; }; // badly named
        t.getCoordinateGrid = function() { return coordinateGrid; }; // specifically for AgendaEventRenderer
        t.getHoverListener = function() { return hoverListener; };
        t.colLeft = colLeft;
        t.colRight = colRight;
        t.colContentLeft = colContentLeft;
        t.colContentRight = colContentRight;
        t.getDaySegmentContainer = function() { return daySegmentContainer; };
        t.getSlotSegmentContainer = function() { return slotSegmentContainer; };
        t.getSlotContainer = function() { return slotContainer; };
        t.getRowCnt = function() { return 1; };
        t.getColCnt = function() { return colCnt; };
        t.getColWidth = function() { return colWidth; };
        t.getSnapHeight = function() { return snapHeight; };
        t.getSnapDuration = function() { return snapDuration; };
        t.getSlotHeight = function() { return slotHeight; };
        t.getSlotDuration = function() { return slotDuration; };
        t.getMinTime = function() { return minTime; };
        t.getMaxTime = function() { return maxTime; };
        t.defaultSelectionEnd = defaultSelectionEnd;
        t.renderDayOverlay = renderDayOverlay;
        t.renderSelection = renderSelection;
        t.clearSelection = clearSelection;
        t.reportDayClick = reportDayClick; // selection mousedown hack
        t.dragStart = dragStart;
        t.dragStop = dragStop;


        // imports
        View.call(t, element, calendar, viewName);
        OverlayManager.call(t);
        SelectionManager.call(t);
        AgendaEventRenderer.call(t);
        var opt = t.opt;
        var trigger = t.trigger;
        var renderOverlay = t.renderOverlay;
        var clearOverlays = t.clearOverlays;
        var reportSelection = t.reportSelection;
        var unselect = t.unselect;
        var daySelectionMousedown = t.daySelectionMousedown;
        var slotSegHtml = t.slotSegHtml;
        var cellToDate = t.cellToDate;
        var dateToCell = t.dateToCell;
        var rangeToSegments = t.rangeToSegments;
        var formatDate = calendar.formatDate;
        var calculateWeekNumber = calendar.calculateWeekNumber;


        // locals

        var dayTable;
        var dayHead;
        var dayHeadCells;
        var dayBody;
        var dayBodyCells;
        var dayBodyCellInners;
        var dayBodyCellContentInners;
        var dayBodyFirstCell;
        var dayBodyFirstCellStretcher;
        var slotLayer;
        var daySegmentContainer;
        var allDayTable;
        var allDayRow;
        var slotScroller;
        var slotContainer;
        var slotSegmentContainer;
        var slotTable;
        var selectionHelper;

        var viewWidth;
        var viewHeight;
        var axisWidth;
        var colWidth;
        var gutterWidth;

        var slotDuration;
        var slotHeight; // TODO: what if slotHeight changes? (see issue 650)

        var snapDuration;
        var snapRatio; // ratio of number of "selection" slots to normal slots. (ex: 1, 2, 4)
        var snapHeight; // holds the pixel hight of a "selection" slot

        var colCnt;
        var slotCnt;
        var coordinateGrid;
        var hoverListener;
        var colPositions;
        var colContentPositions;
        var slotTopCache = {};

        var tm;
        var rtl;
        var minTime;
        var maxTime;
        var colFormat;



        /* Rendering
	-----------------------------------------------------------------------------*/


        disableTextSelection(element.addClass('fc-agenda'));


        function renderAgenda(c) {
            colCnt = c;
            updateOptions();

            if (!dayTable) { // first time rendering?
                buildSkeleton(); // builds day table, slot area, events containers
            }
            else {
                buildDayTable(); // rebuilds day table
            }
        }


        function updateOptions() {

            tm = opt('theme') ? 'ui' : 'fc';
            rtl = opt('isRTL');
            colFormat = opt('columnFormat');

            minTime = moment.duration(opt('minTime'));
            maxTime = moment.duration(opt('maxTime'));

            slotDuration = moment.duration(opt('slotDuration'));
            snapDuration = opt('snapDuration');
            snapDuration = snapDuration ? moment.duration(snapDuration) : slotDuration;
        }



        /* Build DOM
	-----------------------------------------------------------------------*/


        function buildSkeleton() {
            var s;
            var headerClass = tm + "-widget-header";
            var contentClass = tm + "-widget-content";
            var slotTime;
            var slotDate;
            var minutes;
            var slotNormal = slotDuration.asMinutes() % 15 === 0;

            buildDayTable();

            slotLayer =
                $("<div style='position:absolute;z-index:2;left:0;width:100%'/>")
                    .appendTo(element);

            if (opt('allDaySlot')) {

                daySegmentContainer =
                    $("<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>")
                        .appendTo(slotLayer);

                s =
                    "<table style='width:100%' class='fc-agenda-allday' cellspacing='0'>" +
                    "<tr>" +
                    "<th class='" + headerClass + " fc-agenda-axis'>" +
                    (
                        opt('allDayHTML') ||
                        htmlEscape(opt('allDayText'))
                    ) +
                    "</th>" +
                    "<td>" +
                    "<div class='fc-day-content'><div style='position:relative'/></div>" +
                    "</td>" +
                    "<th class='" + headerClass + " fc-agenda-gutter'>&nbsp;</th>" +
                    "</tr>" +
                    "</table>";
                allDayTable = $(s).appendTo(slotLayer);
                allDayRow = allDayTable.find('tr');

                dayBind(allDayRow.find('td'));

                slotLayer.append(
                    "<div class='fc-agenda-divider " + headerClass + "'>" +
                    "<div class='fc-agenda-divider-inner'/>" +
                    "</div>"
                );

            }else{

                daySegmentContainer = $([]); // in jQuery 1.4, we can just do $()

            }

            slotScroller =
                $("<div style='position:absolute;width:100%;overflow-x:hidden;overflow-y:auto'/>")
                    .appendTo(slotLayer);

            slotContainer =
                $("<div style='position:relative;width:100%;overflow:hidden'/>")
                    .appendTo(slotScroller);

            slotSegmentContainer =
                $("<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>")
                    .appendTo(slotContainer);

            s =
                "<table class='fc-agenda-slots' style='width:100%' cellspacing='0'>" +
                "<tbody>";

            slotTime = moment.duration(+minTime); // i wish there was .clone() for durations
            slotCnt = 0;
            while (slotTime < maxTime) {
                slotDate = t.start.clone().time(slotTime); // will be in UTC but that's good. to avoid DST issues
                minutes = slotDate.minutes();
                s +=
                    "<tr class='fc-slot" + slotCnt + ' ' + (!minutes ? '' : 'fc-minor') + "'>" +
                    "<th class='fc-agenda-axis " + headerClass + "'>" +
                    ((!slotNormal || !minutes) ?
                            htmlEscape(formatDate(slotDate, opt('axisFormat'))) :
                            '&nbsp;'
                    ) +
                    "</th>" +
                    "<td class='" + contentClass + "'>" +
                    "<div style='position:relative'>&nbsp;</div>" +
                    "</td>" +
                    "</tr>";
                slotTime.add(slotDuration);
                slotCnt++;
            }

            s +=
                "</tbody>" +
                "</table>";

            slotTable = $(s).appendTo(slotContainer);

            slotBind(slotTable.find('td'));
        }



        /* Build Day Table
	-----------------------------------------------------------------------*/


        function buildDayTable() {
            var html = buildDayTableHTML();

            if (dayTable) {
                dayTable.remove();
            }
            dayTable = $(html).appendTo(element);

            dayHead = dayTable.find('thead');
            dayHeadCells = dayHead.find('th').slice(1, -1); // exclude gutter
            dayBody = dayTable.find('tbody');
            dayBodyCells = dayBody.find('td').slice(0, -1); // exclude gutter
            dayBodyCellInners = dayBodyCells.find('> div');
            dayBodyCellContentInners = dayBodyCells.find('.fc-day-content > div');

            dayBodyFirstCell = dayBodyCells.eq(0);
            dayBodyFirstCellStretcher = dayBodyCellInners.eq(0);

            markFirstLast(dayHead.add(dayHead.find('tr')));
            markFirstLast(dayBody.add(dayBody.find('tr')));

            // TODO: now that we rebuild the cells every time, we should call dayRender
        }


        function buildDayTableHTML() {
            var html =
                "<table style='width:100%' class='fc-agenda-days fc-border-separate' cellspacing='0'>" +
                buildDayTableHeadHTML() +
                buildDayTableBodyHTML() +
                "</table>";

            return html;
        }


        function buildDayTableHeadHTML() {
            var headerClass = tm + "-widget-header";
            var date;
            var html = '';
            var weekText;
            var col;

            html +=
                "<thead>" +
                "<tr>";

            if (opt('weekNumbers')) {
                date = cellToDate(0, 0);
                weekText = calculateWeekNumber(date);
                if (rtl) {
                    weekText += opt('weekNumberTitle');
                }
                else {
                    weekText = opt('weekNumberTitle') + weekText;
                }
                html +=
                    "<th class='fc-agenda-axis fc-week-number " + headerClass + "'>" +
                    htmlEscape(weekText) +
                    "</th>";
            }
            else {
                html += "<th class='fc-agenda-axis " + headerClass + "'>&nbsp;</th>";
            }

            for (col=0; col<colCnt; col++) {
                date = cellToDate(0, col);
                html +=
                    "<th class='fc-" + dayIDs[date.day()] + " fc-col" + col + ' ' + headerClass + "'>" +
                    htmlEscape(formatDate(date, colFormat)) +
                    "</th>";
            }

            html +=
                "<th class='fc-agenda-gutter " + headerClass + "'>&nbsp;</th>" +
                "</tr>" +
                "</thead>";

            return html;
        }


        function buildDayTableBodyHTML() {
            var headerClass = tm + "-widget-header"; // TODO: make these when updateOptions() called
            var contentClass = tm + "-widget-content";
            var date;
            var today = calendar.getNow().stripTime();
            var col;
            var cellsHTML;
            var cellHTML;
            var classNames;
            var html = '';

            html +=
                "<tbody>" +
                "<tr>" +
                "<th class='fc-agenda-axis " + headerClass + "'>&nbsp;</th>";

            cellsHTML = '';

            for (col=0; col<colCnt; col++) {

                date = cellToDate(0, col);

                classNames = [
                    'fc-col' + col,
                    'fc-' + dayIDs[date.day()],
                    contentClass
                ];
                if (date.isSame(today, 'day')) {
                    classNames.push(
                        tm + '-state-highlight',
                        'fc-today'
                    );
                }
                else if (date < today) {
                    classNames.push('fc-past');
                }
                else {
                    classNames.push('fc-future');
                }

                cellHTML =
                    "<td class='" + classNames.join(' ') + "'>" +
                    "<div>" +
                    "<div class='fc-day-content'>" +
                    "<div style='position:relative'>&nbsp;</div>" +
                    "</div>" +
                    "</div>" +
                    "</td>";

                cellsHTML += cellHTML;
            }

            html += cellsHTML;
            html +=
                "<td class='fc-agenda-gutter " + contentClass + "'>&nbsp;</td>" +
                "</tr>" +
                "</tbody>";

            return html;
        }


        // TODO: data-date on the cells



        /* Dimensions
	-----------------------------------------------------------------------*/


        function setHeight(height) {
            if (height === undefined) {
                height = viewHeight;
            }
            viewHeight = height;
            slotTopCache = {};

            var headHeight = dayBody.position().top;
            var allDayHeight = slotScroller.position().top; // including divider
            var bodyHeight = Math.min( // total body height, including borders
                height - headHeight,   // when scrollbars
                slotTable.height() + allDayHeight + 1 // when no scrollbars. +1 for bottom border
            );

            dayBodyFirstCellStretcher
                .height(bodyHeight - vsides(dayBodyFirstCell));

            slotLayer.css('top', headHeight);

            slotScroller.height(bodyHeight - allDayHeight - 1);

            // the stylesheet guarantees that the first row has no border.
            // this allows .height() to work well cross-browser.
            var slotHeight0 = slotTable.find('tr:first').height() + 1; // +1 for bottom border
            var slotHeight1 = slotTable.find('tr:eq(1)').height();
            // HACK: i forget why we do this, but i think a cross-browser issue
            slotHeight = (slotHeight0 + slotHeight1) / 2;

            snapRatio = slotDuration / snapDuration;
            snapHeight = slotHeight / snapRatio;
        }


        function setWidth(width) {
            viewWidth = width;
            colPositions.clear();
            colContentPositions.clear();

            var axisFirstCells = dayHead.find('th:first');
            if (allDayTable) {
                axisFirstCells = axisFirstCells.add(allDayTable.find('th:first'));
            }
            axisFirstCells = axisFirstCells.add(slotTable.find('th:first'));

            axisWidth = 0;
            setOuterWidth(
                axisFirstCells
                    .width('')
                    .each(function(i, _cell) {
                        axisWidth = Math.max(axisWidth, $(_cell).outerWidth());
                    }),
                axisWidth
            );

            var gutterCells = dayTable.find('.fc-agenda-gutter');
            if (allDayTable) {
                gutterCells = gutterCells.add(allDayTable.find('th.fc-agenda-gutter'));
            }

            var slotTableWidth = slotScroller[0].clientWidth; // needs to be done after axisWidth (for IE7)

            gutterWidth = slotScroller.width() - slotTableWidth;
            if (gutterWidth) {
                setOuterWidth(gutterCells, gutterWidth);
                gutterCells
                    .show()
                    .prev()
                    .removeClass('fc-last');
            }else{
                gutterCells
                    .hide()
                    .prev()
                    .addClass('fc-last');
            }

            colWidth = Math.floor((slotTableWidth - axisWidth) / colCnt);
            setOuterWidth(dayHeadCells.slice(0, -1), colWidth);
        }



        /* Scrolling
	-----------------------------------------------------------------------*/


        function resetScroll() {
            var top = computeTimeTop(
                moment.duration(opt('scrollTime'))
            ) + 1; // +1 for the border

            function scroll() {
                slotScroller.scrollTop(top);
            }

            scroll();
            setTimeout(scroll, 0); // overrides any previous scroll state made by the browser
        }


        function afterRender() { // after the view has been freshly rendered and sized
            resetScroll();
        }



        /* Slot/Day clicking and binding
	-----------------------------------------------------------------------*/


        function dayBind(cells) {
            cells.click(slotClick)
                .mousedown(daySelectionMousedown);
        }


        function slotBind(cells) {
            cells.click(slotClick)
                .mousedown(slotSelectionMousedown);
        }


        function slotClick(ev) {
            if (!opt('selectable')) { // if selectable, SelectionManager will worry about dayClick
                var col = Math.min(colCnt-1, Math.floor((ev.pageX - dayTable.offset().left - axisWidth) / colWidth));
                var date = cellToDate(0, col);
                var match = this.parentNode.className.match(/fc-slot(\d+)/); // TODO: maybe use data
                if (match) {
                    var slotIndex = parseInt(match[1], 10);
                    date.add(minTime + slotIndex * slotDuration);
                    date = calendar.rezoneDate(date);
                    trigger(
                        'dayClick',
                        dayBodyCells[col],
                        date,
                        ev
                    );
                }else{
                    trigger(
                        'dayClick',
                        dayBodyCells[col],
                        date,
                        ev
                    );
                }
            }
        }



        /* Semi-transparent Overlay Helpers
	-----------------------------------------------------*/
        // TODO: should be consolidated with BasicView's methods


        function renderDayOverlay(overlayStart, overlayEnd, refreshCoordinateGrid) { // overlayEnd is exclusive

            if (refreshCoordinateGrid) {
                coordinateGrid.build();
            }

            var segments = rangeToSegments(overlayStart, overlayEnd);

            for (var i=0; i<segments.length; i++) {
                var segment = segments[i];
                dayBind(
                    renderCellOverlay(
                        segment.row,
                        segment.leftCol,
                        segment.row,
                        segment.rightCol
                    )
                );
            }
        }


        function renderCellOverlay(row0, col0, row1, col1) { // only for all-day?
            var rect = coordinateGrid.rect(row0, col0, row1, col1, slotLayer);
            return renderOverlay(rect, slotLayer);
        }


        function renderSlotOverlay(overlayStart, overlayEnd) {

            // normalize, because dayStart/dayEnd have stripped time+zone
            overlayStart = overlayStart.clone().stripZone();
            overlayEnd = overlayEnd.clone().stripZone();

            for (var i=0; i<colCnt; i++) { // loop through the day columns

                var dayStart = cellToDate(0, i);
                var dayEnd = dayStart.clone().add('days', 1);

                var stretchStart = dayStart < overlayStart ? overlayStart : dayStart; // the max of the two
                var stretchEnd = dayEnd < overlayEnd ? dayEnd : overlayEnd; // the min of the two

                if (stretchStart < stretchEnd) {
                    var rect = coordinateGrid.rect(0, i, 0, i, slotContainer); // only use it for horizontal coords
                    var top = computeDateTop(stretchStart, dayStart);
                    var bottom = computeDateTop(stretchEnd, dayStart);

                    rect.top = top;
                    rect.height = bottom - top;
                    slotBind(
                        renderOverlay(rect, slotContainer)
                    );
                }
            }
        }



        /* Coordinate Utilities
	-----------------------------------------------------------------------------*/


        coordinateGrid = new CoordinateGrid(function(rows, cols) {
            var e, n, p;
            dayHeadCells.each(function(i, _e) {
                e = $(_e);
                n = e.offset().left;
                if (i) {
                    p[1] = n;
                }
                p = [n];
                cols[i] = p;
            });
            p[1] = n + e.outerWidth();
            if (opt('allDaySlot')) {
                e = allDayRow;
                n = e.offset().top;
                rows[0] = [n, n+e.outerHeight()];
            }
            var slotTableTop = slotContainer.offset().top;
            var slotScrollerTop = slotScroller.offset().top;
            var slotScrollerBottom = slotScrollerTop + slotScroller.outerHeight();
            function constrain(n) {
                return Math.max(slotScrollerTop, Math.min(slotScrollerBottom, n));
            }
            for (var i=0; i<slotCnt*snapRatio; i++) { // adapt slot count to increased/decreased selection slot count
                rows.push([
                    constrain(slotTableTop + snapHeight*i),
                    constrain(slotTableTop + snapHeight*(i+1))
                ]);
            }
        });


        hoverListener = new HoverListener(coordinateGrid);

        colPositions = new HorizontalPositionCache(function(col) {
            return dayBodyCellInners.eq(col);
        });

        colContentPositions = new HorizontalPositionCache(function(col) {
            return dayBodyCellContentInners.eq(col);
        });


        function colLeft(col) {
            return colPositions.left(col);
        }


        function colContentLeft(col) {
            return colContentPositions.left(col);
        }


        function colRight(col) {
            return colPositions.right(col);
        }


        function colContentRight(col) {
            return colContentPositions.right(col);
        }


        // NOTE: the row index of these "cells" doesn't correspond to the slot index, but rather the "snap" index


        function getIsCellAllDay(cell) { // TODO: remove because mom.hasTime() from realCellToDate() is better
            return opt('allDaySlot') && !cell.row;
        }


        function realCellToDate(cell) { // ugh "real" ... but blame it on our abuse of the "cell" system
            var date = cellToDate(0, cell.col);
            var snapIndex = cell.row;

            if (opt('allDaySlot')) {
                snapIndex--;
            }

            if (snapIndex >= 0) {
                date.time(moment.duration(minTime + snapIndex * snapDuration));
                date = calendar.rezoneDate(date);
            }

            return date;
        }


        function computeDateTop(date, startOfDayDate) {
            return computeTimeTop(
                moment.duration(
                    date.clone().stripZone() - startOfDayDate.clone().stripTime()
                )
            );
        }


        function computeTimeTop(time) { // time is a duration

            if (time < minTime) {
                return 0;
            }
            if (time >= maxTime) {
                return slotTable.height();
            }

            var slots = (time - minTime) / slotDuration;
            var slotIndex = Math.floor(slots);
            var slotPartial = slots - slotIndex;
            var slotTop = slotTopCache[slotIndex];

            // find the position of the corresponding <tr>
            // need to use this tecnhique because not all rows are rendered at same height sometimes.
            if (slotTop === undefined) {
                slotTop = slotTopCache[slotIndex] =
                    slotTable.find('tr').eq(slotIndex).find('td div')[0].offsetTop;
                // .eq() is faster than ":eq()" selector
                // [0].offsetTop is faster than .position().top (do we really need this optimization?)
                // a better optimization would be to cache all these divs
            }

            var top =
                slotTop - 1 + // because first row doesn't have a top border
                slotPartial * slotHeight; // part-way through the row

            top = Math.max(top, 0);

            return top;
        }



        /* Selection
	---------------------------------------------------------------------------------*/


        function defaultSelectionEnd(start) {
            if (start.hasTime()) {
                return start.clone().add(slotDuration);
            }
            else {
                return start.clone().add('days', 1);
            }
        }


        function renderSelection(start, end) {
            if (start.hasTime() || end.hasTime()) {
                renderSlotSelection(start, end);
            }
            else if (opt('allDaySlot')) {
                renderDayOverlay(start, end, true); // true for refreshing coordinate grid
            }
        }


        function renderSlotSelection(startDate, endDate) {
            var helperOption = opt('selectHelper');
            coordinateGrid.build();
            if (helperOption) {
                var col = dateToCell(startDate).col;
                if (col >= 0 && col < colCnt) { // only works when times are on same day
                    var rect = coordinateGrid.rect(0, col, 0, col, slotContainer); // only for horizontal coords
                    var top = computeDateTop(startDate, startDate);
                    var bottom = computeDateTop(endDate, startDate);
                    if (bottom > top) { // protect against selections that are entirely before or after visible range
                        rect.top = top;
                        rect.height = bottom - top;
                        rect.left += 2;
                        rect.width -= 5;
                        if ($.isFunction(helperOption)) {
                            var helperRes = helperOption(startDate, endDate);
                            if (helperRes) {
                                rect.position = 'absolute';
                                selectionHelper = $(helperRes)
                                    .css(rect)
                                    .appendTo(slotContainer);
                            }
                        }else{
                            rect.isStart = true; // conside rect a "seg" now
                            rect.isEnd = true;   //
                            selectionHelper = $(slotSegHtml(
                                {
                                    title: '',
                                    start: startDate,
                                    end: endDate,
                                    className: ['fc-select-helper'],
                                    editable: false
                                },
                                rect
                            ));
                            selectionHelper.css('opacity', opt('dragOpacity'));
                        }
                        if (selectionHelper) {
                            slotBind(selectionHelper);
                            slotContainer.append(selectionHelper);
                            setOuterWidth(selectionHelper, rect.width, true); // needs to be after appended
                            setOuterHeight(selectionHelper, rect.height, true);
                        }
                    }
                }
            }else{
                renderSlotOverlay(startDate, endDate);
            }
        }


        function clearSelection() {
            clearOverlays();
            if (selectionHelper) {
                selectionHelper.remove();
                selectionHelper = null;
            }
        }


        function slotSelectionMousedown(ev) {
            if (ev.which == 1 && opt('selectable')) { // ev.which==1 means left mouse button
                unselect(ev);
                var dates;
                hoverListener.start(function(cell, origCell) {
                    clearSelection();
                    if (cell && cell.col == origCell.col && !getIsCellAllDay(cell)) {
                        var d1 = realCellToDate(origCell);
                        var d2 = realCellToDate(cell);
                        dates = [
                            d1,
                            d1.clone().add(snapDuration), // calculate minutes depending on selection slot minutes
                            d2,
                            d2.clone().add(snapDuration)
                        ].sort(dateCompare);
                        renderSlotSelection(dates[0], dates[3]);
                    }else{
                        dates = null;
                    }
                }, ev);
                $(document).one('mouseup', function(ev) {
                    hoverListener.stop();
                    if (dates) {
                        if (+dates[0] == +dates[1]) {
                            reportDayClick(dates[0], ev);
                        }
                        reportSelection(dates[0], dates[3], ev);
                    }
                });
            }
        }


        function reportDayClick(date, ev) {
            trigger('dayClick', dayBodyCells[dateToCell(date).col], date, ev);
        }



        /* External Dragging
	--------------------------------------------------------------------------------*/


        function dragStart(_dragElement, ev, ui) {
            hoverListener.start(function(cell) {
                clearOverlays();
                if (cell) {
                    var d1 = realCellToDate(cell);
                    var d2 = d1.clone();
                    if (d1.hasTime()) {
                        d2.add(calendar.defaultTimedEventDuration);
                        renderSlotOverlay(d1, d2);
                    }
                    else {
                        d2.add(calendar.defaultAllDayEventDuration);
                        renderDayOverlay(d1, d2);
                    }
                }
            }, ev);
        }


        function dragStop(_dragElement, ev, ui) {
            var cell = hoverListener.stop();
            clearOverlays();
            if (cell) {
                trigger(
                    'drop',
                    _dragElement,
                    realCellToDate(cell),
                    ev,
                    ui
                );
            }
        }


    }

    ;;

    function AgendaEventRenderer() {
        var t = this;


        // exports
        t.renderEvents = renderEvents;
        t.clearEvents = clearEvents;
        t.slotSegHtml = slotSegHtml;


        // imports
        DayEventRenderer.call(t);
        var opt = t.opt;
        var trigger = t.trigger;
        var isEventDraggable = t.isEventDraggable;
        var isEventResizable = t.isEventResizable;
        var eventElementHandlers = t.eventElementHandlers;
        var setHeight = t.setHeight;
        var getDaySegmentContainer = t.getDaySegmentContainer;
        var getSlotSegmentContainer = t.getSlotSegmentContainer;
        var getHoverListener = t.getHoverListener;
        var computeDateTop = t.computeDateTop;
        var getIsCellAllDay = t.getIsCellAllDay;
        var colContentLeft = t.colContentLeft;
        var colContentRight = t.colContentRight;
        var cellToDate = t.cellToDate;
        var getColCnt = t.getColCnt;
        var getColWidth = t.getColWidth;
        var getSnapHeight = t.getSnapHeight;
        var getSnapDuration = t.getSnapDuration;
        var getSlotHeight = t.getSlotHeight;
        var getSlotDuration = t.getSlotDuration;
        var getSlotContainer = t.getSlotContainer;
        var reportEventElement = t.reportEventElement;
        var showEvents = t.showEvents;
        var hideEvents = t.hideEvents;
        var eventDrop = t.eventDrop;
        var eventResize = t.eventResize;
        var renderDayOverlay = t.renderDayOverlay;
        var clearOverlays = t.clearOverlays;
        var renderDayEvents = t.renderDayEvents;
        var getMinTime = t.getMinTime;
        var getMaxTime = t.getMaxTime;
        var calendar = t.calendar;
        var formatDate = calendar.formatDate;
        var getEventEnd = calendar.getEventEnd;


        // overrides
        t.draggableDayEvent = draggableDayEvent;



        /* Rendering
	----------------------------------------------------------------------------*/


        function renderEvents(events, modifiedEventId) {
            var i, len=events.length,
                dayEvents=[],
                slotEvents=[];
            for (i=0; i<len; i++) {
                if (events[i].allDay) {
                    dayEvents.push(events[i]);
                }else{
                    slotEvents.push(events[i]);
                }
            }

            if (opt('allDaySlot')) {
                renderDayEvents(dayEvents, modifiedEventId);
                setHeight(); // no params means set to viewHeight
            }

            renderSlotSegs(compileSlotSegs(slotEvents), modifiedEventId);
        }


        function clearEvents() {
            getDaySegmentContainer().empty();
            getSlotSegmentContainer().empty();
        }


        function compileSlotSegs(events) {
            var colCnt = getColCnt(),
                minTime = getMinTime(),
                maxTime = getMaxTime(),
                cellDate,
                i,
                j, seg,
                colSegs,
                segs = [];

            for (i=0; i<colCnt; i++) {
                cellDate = cellToDate(0, i);

                colSegs = sliceSegs(
                    events,
                    cellDate.clone().time(minTime),
                    cellDate.clone().time(maxTime)
                );

                colSegs = placeSlotSegs(colSegs); // returns a new order

                for (j=0; j<colSegs.length; j++) {
                    seg = colSegs[j];
                    seg.col = i;
                    segs.push(seg);
                }
            }

            return segs;
        }


        function sliceSegs(events, rangeStart, rangeEnd) {

            // normalize, because all dates will be compared w/o zones
            rangeStart = rangeStart.clone().stripZone();
            rangeEnd = rangeEnd.clone().stripZone();

            var segs = [],
                i, len=events.length, event,
                eventStart, eventEnd,
                segStart, segEnd,
                isStart, isEnd;
            for (i=0; i<len; i++) {

                event = events[i];

                // get dates, make copies, then strip zone to normalize
                eventStart = event.start.clone().stripZone();
                eventEnd = getEventEnd(event).stripZone();

                if (eventEnd > rangeStart && eventStart < rangeEnd) {

                    if (eventStart < rangeStart) {
                        segStart = rangeStart.clone();
                        isStart = false;
                    }
                    else {
                        segStart = eventStart;
                        isStart = true;
                    }

                    if (eventEnd > rangeEnd) {
                        segEnd = rangeEnd.clone();
                        isEnd = false;
                    }
                    else {
                        segEnd = eventEnd;
                        isEnd = true;
                    }

                    segs.push({
                        event: event,
                        start: segStart,
                        end: segEnd,
                        isStart: isStart,
                        isEnd: isEnd
                    });
                }
            }

            return segs.sort(compareSlotSegs);
        }


        // renders events in the 'time slots' at the bottom
        // TODO: when we refactor this, when user returns `false` eventRender, don't have empty space
        // TODO: refactor will include using pixels to detect collisions instead of dates (handy for seg cmp)

        function renderSlotSegs(segs, modifiedEventId) {

            var i, segCnt=segs.length, seg,
                event,
                top,
                bottom,
                columnLeft,
                columnRight,
                columnWidth,
                width,
                left,
                right,
                html = '',
                eventElements,
                eventElement,
                triggerRes,
                titleElement,
                height,
                slotSegmentContainer = getSlotSegmentContainer(),
                isRTL = opt('isRTL');

            // calculate position/dimensions, create html
            for (i=0; i<segCnt; i++) {
                seg = segs[i];
                event = seg.event;
                top = computeDateTop(seg.start, seg.start);
                bottom = computeDateTop(seg.end, seg.start);
                columnLeft = colContentLeft(seg.col);
                columnRight = colContentRight(seg.col);
                columnWidth = columnRight - columnLeft;

                // shave off space on right near scrollbars (2.5%)
                // TODO: move this to CSS somehow
                columnRight -= columnWidth * .025;
                columnWidth = columnRight - columnLeft;

                width = columnWidth * (seg.forwardCoord - seg.backwardCoord);

                if (opt('slotEventOverlap')) {
                    // double the width while making sure resize handle is visible
                    // (assumed to be 20px wide)
                    width = Math.max(
                        (width - (20/2)) * 2,
                        width // narrow columns will want to make the segment smaller than
                        // the natural width. don't allow it
                    );
                }

                if (isRTL) {
                    right = columnRight - seg.backwardCoord * columnWidth;
                    left = right - width;
                }
                else {
                    left = columnLeft + seg.backwardCoord * columnWidth;
                    right = left + width;
                }

                // make sure horizontal coordinates are in bounds
                left = Math.max(left, columnLeft);
                right = Math.min(right, columnRight);
                width = right - left;

                seg.top = top;
                seg.left = left;
                seg.outerWidth = width;
                seg.outerHeight = bottom - top;
                html += slotSegHtml(event, seg);
            }

            slotSegmentContainer[0].innerHTML = html; // faster than html()
            eventElements = slotSegmentContainer.children();

            // retrieve elements, run through eventRender callback, bind event handlers
            for (i=0; i<segCnt; i++) {
                seg = segs[i];
                event = seg.event;
                eventElement = $(eventElements[i]); // faster than eq()
                triggerRes = trigger('eventRender', event, event, eventElement);
                if (triggerRes === false) {
                    eventElement.remove();
                }else{
                    if (triggerRes && triggerRes !== true) {
                        eventElement.remove();
                        eventElement = $(triggerRes)
                            .css({
                                position: 'absolute',
                                top: seg.top,
                                left: seg.left
                            })
                            .appendTo(slotSegmentContainer);
                    }
                    seg.element = eventElement;
                    if (event._id === modifiedEventId) {
                        bindSlotSeg(event, eventElement, seg);
                    }else{
                        eventElement[0]._fci = i; // for lazySegBind
                    }
                    reportEventElement(event, eventElement);
                }
            }

            lazySegBind(slotSegmentContainer, segs, bindSlotSeg);

            // record event sides and title positions
            for (i=0; i<segCnt; i++) {
                seg = segs[i];
                if ((eventElement = seg.element)) {
                    seg.vsides = vsides(eventElement, true);
                    seg.hsides = hsides(eventElement, true);
                    titleElement = eventElement.find('.fc-event-title');
                    if (titleElement.length) {
                        seg.contentTop = titleElement[0].offsetTop;
                    }
                }
            }

            // set all positions/dimensions at once
            for (i=0; i<segCnt; i++) {
                seg = segs[i];
                if ((eventElement = seg.element)) {
                    eventElement[0].style.width = Math.max(0, seg.outerWidth - seg.hsides) + 'px';
                    height = Math.max(0, seg.outerHeight - seg.vsides);
                    eventElement[0].style.height = height + 'px';
                    event = seg.event;
                    if (seg.contentTop !== undefined && height - seg.contentTop < 10) {
                        // not enough room for title, put it in the time (TODO: maybe make both display:inline instead)
                        eventElement.find('div.fc-event-time')
                            .text(
                                formatDate(event.start, opt('timeFormat')) + ' - ' + event.title
                            );
                        eventElement.find('div.fc-event-title')
                            .remove();
                    }
                    trigger('eventAfterRender', event, event, eventElement);
                }
            }

        }


        function slotSegHtml(event, seg) {
            var html = "<";
            var url = event.url;
            var skinCss = getSkinCss(event, opt);
            var classes = ['fc-event', 'fc-event-vert'];
            if (isEventDraggable(event)) {
                classes.push('fc-event-draggable');
            }
            if (seg.isStart) {
                classes.push('fc-event-start');
            }
            if (seg.isEnd) {
                classes.push('fc-event-end');
            }
            classes = classes.concat(event.className);
            if (event.source) {
                classes = classes.concat(event.source.className || []);
            }
            if (url) {
                html += "a href='" + htmlEscape(event.url) + "'";
            }else{
                html += "div";
            }

            html +=
                " class='" + classes.join(' ') + "'" +
                " style=" +
                "'" +
                "position:absolute;" +
                "top:" + seg.top + "px;" +
                "left:" + seg.left + "px;" +
                skinCss +
                "'" +
                ">" +
                "<div class='fc-event-inner'>" +
                "<div class='fc-event-time'>" +
                htmlEscape(t.getEventTimeText(event)) +
                "</div>" +
                "<div class='fc-event-title'>" +
                htmlEscape(event.title || '') +
                "</div>" +
                "</div>" +
                "<div class='fc-event-bg'></div>";

            if (seg.isEnd && isEventResizable(event)) {
                html +=
                    "<div class='ui-resizable-handle ui-resizable-s'>=</div>";
            }
            html +=
                "</" + (url ? "a" : "div") + ">";
            return html;
        }


        function bindSlotSeg(event, eventElement, seg) {
            var timeElement = eventElement.find('div.fc-event-time');
            if (isEventDraggable(event)) {
                draggableSlotEvent(event, eventElement, timeElement);
            }
            if (seg.isEnd && isEventResizable(event)) {
                resizableSlotEvent(event, eventElement, timeElement);
            }
            eventElementHandlers(event, eventElement);
        }



        /* Dragging
	-----------------------------------------------------------------------------------*/


        // when event starts out FULL-DAY
        // overrides DayEventRenderer's version because it needs to account for dragging elements
        // to and from the slot area.

        function draggableDayEvent(event, eventElement, seg) {
            var isStart = seg.isStart;
            var origWidth;
            var revert;
            var allDay = true;
            var dayDelta;

            var hoverListener = getHoverListener();
            var colWidth = getColWidth();
            var minTime = getMinTime();
            var slotDuration = getSlotDuration();
            var slotHeight = getSlotHeight();
            var snapDuration = getSnapDuration();
            var snapHeight = getSnapHeight();

            eventElement.draggable({
                opacity: opt('dragOpacity', 'month'), // use whatever the month view was using
                revertDuration: opt('dragRevertDuration'),
                start: function(ev, ui) {

                    trigger('eventDragStart', eventElement[0], event, ev, ui);
                    hideEvents(event, eventElement);
                    origWidth = eventElement.width();

                    hoverListener.start(function(cell, origCell) {
                        clearOverlays();
                        if (cell) {
                            revert = false;

                            var origDate = cellToDate(0, origCell.col);
                            var date = cellToDate(0, cell.col);
                            dayDelta = date.diff(origDate, 'days');

                            if (!cell.row) { // on full-days

                                renderDayOverlay(
                                    event.start.clone().add('days', dayDelta),
                                    getEventEnd(event).add('days', dayDelta)
                                );

                                resetElement();
                            }
                            else { // mouse is over bottom slots

                                if (isStart) {
                                    if (allDay) {
                                        // convert event to temporary slot-event
                                        eventElement.width(colWidth - 10); // don't use entire width
                                        setOuterHeight(eventElement, calendar.defaultTimedEventDuration / slotDuration * slotHeight); // the default height
                                        eventElement.draggable('option', 'grid', [ colWidth, 1 ]);
                                        allDay = false;
                                    }
                                }
                                else {
                                    revert = true;
                                }
                            }

                            revert = revert || (allDay && !dayDelta);
                        }
                        else {
                            resetElement();
                            revert = true;
                        }

                        eventElement.draggable('option', 'revert', revert);

                    }, ev, 'drag');
                },
                stop: function(ev, ui) {
                    hoverListener.stop();
                    clearOverlays();
                    trigger('eventDragStop', eventElement[0], event, ev, ui);

                    if (revert) { // hasn't moved or is out of bounds (draggable has already reverted)

                        resetElement();
                        eventElement.css('filter', ''); // clear IE opacity side-effects
                        showEvents(event, eventElement);
                    }
                    else { // changed!

                        var eventStart = event.start.clone().add('days', dayDelta); // already assumed to have a stripped time
                        var snapTime;
                        var snapIndex;
                        if (!allDay) {
                            snapIndex = Math.round((eventElement.offset().top - getSlotContainer().offset().top) / snapHeight); // why not use ui.offset.top?
                            snapTime = moment.duration(minTime + snapIndex * snapDuration);
                            eventStart = calendar.rezoneDate(eventStart.clone().time(snapTime));
                        }

                        eventDrop(
                            eventElement[0],
                            event,
                            eventStart,
                            ev,
                            ui
                        );
                    }
                }
            });
            function resetElement() {
                if (!allDay) {
                    eventElement
                        .width(origWidth)
                        .height('')
                        .draggable('option', 'grid', null);
                    allDay = true;
                }
            }
        }


        // when event starts out IN TIMESLOTS

        function draggableSlotEvent(event, eventElement, timeElement) {
            var coordinateGrid = t.getCoordinateGrid();
            var colCnt = getColCnt();
            var colWidth = getColWidth();
            var snapHeight = getSnapHeight();
            var snapDuration = getSnapDuration();

            // states
            var origPosition; // original position of the element, not the mouse
            var origCell;
            var isInBounds, prevIsInBounds;
            var isAllDay, prevIsAllDay;
            var colDelta, prevColDelta;
            var dayDelta; // derived from colDelta
            var snapDelta, prevSnapDelta; // the number of snaps away from the original position

            // newly computed
            var eventStart, eventEnd;

            eventElement.draggable({
                scroll: false,
                grid: [ colWidth, snapHeight ],
                axis: colCnt==1 ? 'y' : false,
                opacity: opt('dragOpacity'),
                revertDuration: opt('dragRevertDuration'),
                start: function(ev, ui) {

                    trigger('eventDragStart', eventElement[0], event, ev, ui);
                    hideEvents(event, eventElement);

                    coordinateGrid.build();

                    // initialize states
                    origPosition = eventElement.position();
                    origCell = coordinateGrid.cell(ev.pageX, ev.pageY);
                    isInBounds = prevIsInBounds = true;
                    isAllDay = prevIsAllDay = getIsCellAllDay(origCell);
                    colDelta = prevColDelta = 0;
                    dayDelta = 0;
                    snapDelta = prevSnapDelta = 0;

                    eventStart = null;
                    eventEnd = null;
                },
                drag: function(ev, ui) {

                    // NOTE: this `cell` value is only useful for determining in-bounds and all-day.
                    // Bad for anything else due to the discrepancy between the mouse position and the
                    // element position while snapping. (problem revealed in PR #55)
                    //
                    // PS- the problem exists for draggableDayEvent() when dragging an all-day event to a slot event.
                    // We should overhaul the dragging system and stop relying on jQuery UI.
                    var cell = coordinateGrid.cell(ev.pageX, ev.pageY);

                    // update states
                    isInBounds = !!cell;
                    if (isInBounds) {
                        isAllDay = getIsCellAllDay(cell);

                        // calculate column delta
                        colDelta = Math.round((ui.position.left - origPosition.left) / colWidth);
                        if (colDelta != prevColDelta) {
                            // calculate the day delta based off of the original clicked column and the column delta
                            var origDate = cellToDate(0, origCell.col);
                            var col = origCell.col + colDelta;
                            col = Math.max(0, col);
                            col = Math.min(colCnt-1, col);
                            var date = cellToDate(0, col);
                            dayDelta = date.diff(origDate, 'days');
                        }

                        // calculate minute delta (only if over slots)
                        if (!isAllDay) {
                            snapDelta = Math.round((ui.position.top - origPosition.top) / snapHeight);
                        }
                    }

                    // any state changes?
                    if (
                        isInBounds != prevIsInBounds ||
                        isAllDay != prevIsAllDay ||
                        colDelta != prevColDelta ||
                        snapDelta != prevSnapDelta
                    ) {

                        // compute new dates
                        if (isAllDay) {
                            eventStart = event.start.clone().stripTime().add('days', dayDelta);
                            eventEnd = eventStart.clone().add(calendar.defaultAllDayEventDuration);
                        }
                        else {
                            eventStart = event.start.clone().add(snapDelta * snapDuration).add('days', dayDelta);
                            eventEnd = getEventEnd(event).add(snapDelta * snapDuration).add('days', dayDelta);
                        }

                        updateUI();

                        // update previous states for next time
                        prevIsInBounds = isInBounds;
                        prevIsAllDay = isAllDay;
                        prevColDelta = colDelta;
                        prevSnapDelta = snapDelta;
                    }

                    // if out-of-bounds, revert when done, and vice versa.
                    eventElement.draggable('option', 'revert', !isInBounds);

                },
                stop: function(ev, ui) {

                    clearOverlays();
                    trigger('eventDragStop', eventElement[0], event, ev, ui);

                    if (isInBounds && (isAllDay || dayDelta || snapDelta)) { // changed!
                        eventDrop(
                            eventElement[0],
                            event,
                            eventStart,
                            ev,
                            ui
                        );
                    }
                    else { // either no change or out-of-bounds (draggable has already reverted)

                        // reset states for next time, and for updateUI()
                        isInBounds = true;
                        isAllDay = false;
                        colDelta = 0;
                        dayDelta = 0;
                        snapDelta = 0;

                        updateUI();
                        eventElement.css('filter', ''); // clear IE opacity side-effects

                        // sometimes fast drags make event revert to wrong position, so reset.
                        // also, if we dragged the element out of the area because of snapping,
                        // but the *mouse* is still in bounds, we need to reset the position.
                        eventElement.css(origPosition);

                        showEvents(event, eventElement);
                    }
                }
            });

            function updateUI() {
                clearOverlays();
                if (isInBounds) {
                    if (isAllDay) {
                        timeElement.hide();
                        eventElement.draggable('option', 'grid', null); // disable grid snapping
                        renderDayOverlay(eventStart, eventEnd);
                    }
                    else {
                        updateTimeText();
                        timeElement.css('display', ''); // show() was causing display=inline
                        eventElement.draggable('option', 'grid', [colWidth, snapHeight]); // re-enable grid snapping
                    }
                }
            }

            function updateTimeText() {
                if (eventStart) { // must of had a state change
                    timeElement.text(
                        t.getEventTimeText(eventStart, event.end ? eventEnd : null)
                        //                                       ^
                        // only display the new end if there was an old end
                    );
                }
            }

        }



        /* Resizing
	--------------------------------------------------------------------------------------*/


        function resizableSlotEvent(event, eventElement, timeElement) {
            var snapDelta, prevSnapDelta;
            var snapHeight = getSnapHeight();
            var snapDuration = getSnapDuration();
            var eventEnd;

            eventElement.resizable({
                handles: {
                    s: '.ui-resizable-handle'
                },
                grid: snapHeight,
                start: function(ev, ui) {
                    snapDelta = prevSnapDelta = 0;
                    hideEvents(event, eventElement);
                    trigger('eventResizeStart', eventElement[0], event, ev, ui);
                },
                resize: function(ev, ui) {
                    // don't rely on ui.size.height, doesn't take grid into account
                    snapDelta = Math.round((Math.max(snapHeight, eventElement.height()) - ui.originalSize.height) / snapHeight);
                    if (snapDelta != prevSnapDelta) {
                        eventEnd = getEventEnd(event).add(snapDuration * snapDelta);
                        var text;
                        if (snapDelta) { // has there been a change?
                            text = t.getEventTimeText(event.start, eventEnd);
                        }
                        else {
                            text = t.getEventTimeText(event); // the original time text
                        }
                        timeElement.text(text);
                        prevSnapDelta = snapDelta;
                    }
                },
                stop: function(ev, ui) {
                    trigger('eventResizeStop', eventElement[0], event, ev, ui);
                    if (snapDelta) {
                        eventResize(
                            eventElement[0],
                            event,
                            eventEnd,
                            ev,
                            ui
                        );
                    }
                    else {
                        showEvents(event, eventElement);
                        // BUG: if event was really short, need to put title back in span
                    }
                }
            });
        }


    }



    /* Agenda Event Segment Utilities
-----------------------------------------------------------------------------*/


// Sets the seg.backwardCoord and seg.forwardCoord on each segment and returns a new
// list in the order they should be placed into the DOM (an implicit z-index).
    function placeSlotSegs(segs) {
        var levels = buildSlotSegLevels(segs);
        var level0 = levels[0];
        var i;

        computeForwardSlotSegs(levels);

        if (level0) {

            for (i=0; i<level0.length; i++) {
                computeSlotSegPressures(level0[i]);
            }

            for (i=0; i<level0.length; i++) {
                computeSlotSegCoords(level0[i], 0, 0);
            }
        }

        return flattenSlotSegLevels(levels);
    }


// Builds an array of segments "levels". The first level will be the leftmost tier of segments
// if the calendar is left-to-right, or the rightmost if the calendar is right-to-left.
    function buildSlotSegLevels(segs) {
        var levels = [];
        var i, seg;
        var j;

        for (i=0; i<segs.length; i++) {
            seg = segs[i];

            // go through all the levels and stop on the first level where there are no collisions
            for (j=0; j<levels.length; j++) {
                if (!computeSlotSegCollisions(seg, levels[j]).length) {
                    break;
                }
            }

            (levels[j] || (levels[j] = [])).push(seg);
        }

        return levels;
    }


// For every segment, figure out the other segments that are in subsequent
// levels that also occupy the same vertical space. Accumulate in seg.forwardSegs
    function computeForwardSlotSegs(levels) {
        var i, level;
        var j, seg;
        var k;

        for (i=0; i<levels.length; i++) {
            level = levels[i];

            for (j=0; j<level.length; j++) {
                seg = level[j];

                seg.forwardSegs = [];
                for (k=i+1; k<levels.length; k++) {
                    computeSlotSegCollisions(seg, levels[k], seg.forwardSegs);
                }
            }
        }
    }


// Figure out which path forward (via seg.forwardSegs) results in the longest path until
// the furthest edge is reached. The number of segments in this path will be seg.forwardPressure
    function computeSlotSegPressures(seg) {
        var forwardSegs = seg.forwardSegs;
        var forwardPressure = 0;
        var i, forwardSeg;

        if (seg.forwardPressure === undefined) { // not already computed

            for (i=0; i<forwardSegs.length; i++) {
                forwardSeg = forwardSegs[i];

                // figure out the child's maximum forward path
                computeSlotSegPressures(forwardSeg);

                // either use the existing maximum, or use the child's forward pressure
                // plus one (for the forwardSeg itself)
                forwardPressure = Math.max(
                    forwardPressure,
                    1 + forwardSeg.forwardPressure
                );
            }

            seg.forwardPressure = forwardPressure;
        }
    }


// Calculate seg.forwardCoord and seg.backwardCoord for the segment, where both values range
// from 0 to 1. If the calendar is left-to-right, the seg.backwardCoord maps to "left" and
// seg.forwardCoord maps to "right" (via percentage). Vice-versa if the calendar is right-to-left.
//
// The segment might be part of a "series", which means consecutive segments with the same pressure
// who's width is unknown until an edge has been hit. `seriesBackwardPressure` is the number of
// segments behind this one in the current series, and `seriesBackwardCoord` is the starting
// coordinate of the first segment in the series.
    function computeSlotSegCoords(seg, seriesBackwardPressure, seriesBackwardCoord) {
        var forwardSegs = seg.forwardSegs;
        var i;

        if (seg.forwardCoord === undefined) { // not already computed

            if (!forwardSegs.length) {

                // if there are no forward segments, this segment should butt up against the edge
                seg.forwardCoord = 1;
            }
            else {

                // sort highest pressure first
                forwardSegs.sort(compareForwardSlotSegs);

                // this segment's forwardCoord will be calculated from the backwardCoord of the
                // highest-pressure forward segment.
                computeSlotSegCoords(forwardSegs[0], seriesBackwardPressure + 1, seriesBackwardCoord);
                seg.forwardCoord = forwardSegs[0].backwardCoord;
            }

            // calculate the backwardCoord from the forwardCoord. consider the series
            seg.backwardCoord = seg.forwardCoord -
                (seg.forwardCoord - seriesBackwardCoord) / // available width for series
                (seriesBackwardPressure + 1); // # of segments in the series

            // use this segment's coordinates to computed the coordinates of the less-pressurized
            // forward segments
            for (i=0; i<forwardSegs.length; i++) {
                computeSlotSegCoords(forwardSegs[i], 0, seg.forwardCoord);
            }
        }
    }


// Outputs a flat array of segments, from lowest to highest level
    function flattenSlotSegLevels(levels) {
        var segs = [];
        var i, level;
        var j;

        for (i=0; i<levels.length; i++) {
            level = levels[i];

            for (j=0; j<level.length; j++) {
                segs.push(level[j]);
            }
        }

        return segs;
    }


// Find all the segments in `otherSegs` that vertically collide with `seg`.
// Append into an optionally-supplied `results` array and return.
    function computeSlotSegCollisions(seg, otherSegs, results) {
        results = results || [];

        for (var i=0; i<otherSegs.length; i++) {
            if (isSlotSegCollision(seg, otherSegs[i])) {
                results.push(otherSegs[i]);
            }
        }

        return results;
    }


// Do these segments occupy the same vertical space?
    function isSlotSegCollision(seg1, seg2) {
        return seg1.end > seg2.start && seg1.start < seg2.end;
    }


// A cmp function for determining which forward segment to rely on more when computing coordinates.
    function compareForwardSlotSegs(seg1, seg2) {
        // put higher-pressure first
        return seg2.forwardPressure - seg1.forwardPressure ||
            // put segments that are closer to initial edge first (and favor ones with no coords yet)
            (seg1.backwardCoord || 0) - (seg2.backwardCoord || 0) ||
            // do normal sorting...
            compareSlotSegs(seg1, seg2);
    }


// A cmp function for determining which segment should be closer to the initial edge
// (the left edge on a left-to-right calendar).
    function compareSlotSegs(seg1, seg2) {
        return seg1.start - seg2.start || // earlier start time goes first
            (seg2.end - seg2.start) - (seg1.end - seg1.start) || // tie? longer-duration goes first
            (seg1.event.title || '').localeCompare(seg2.event.title); // tie? alphabetically by title
    }


    ;;


    function View(element, calendar, viewName) {
        var t = this;


        // exports
        t.element = element;
        t.calendar = calendar;
        t.name = viewName;
        t.opt = opt;
        t.trigger = trigger;
        t.isEventDraggable = isEventDraggable;
        t.isEventResizable = isEventResizable;
        t.clearEventData = clearEventData;
        t.reportEventElement = reportEventElement;
        t.triggerEventDestroy = triggerEventDestroy;
        t.eventElementHandlers = eventElementHandlers;
        t.showEvents = showEvents;
        t.hideEvents = hideEvents;
        t.eventDrop = eventDrop;
        t.eventResize = eventResize;
        // t.start, t.end // moments with ambiguous-time
        // t.intervalStart, t.intervalEnd // moments with ambiguous-time


        // imports
        var reportEventChange = calendar.reportEventChange;


        // locals
        var eventElementsByID = {}; // eventID mapped to array of jQuery elements
        var eventElementCouples = []; // array of objects, { event, element } // TODO: unify with segment system
        var options = calendar.options;
        var nextDayThreshold = moment.duration(options.nextDayThreshold);




        function opt(name, viewNameOverride) {
            var v = options[name];
            if ($.isPlainObject(v) && !isForcedAtomicOption(name)) {
                return smartProperty(v, viewNameOverride || viewName);
            }
            return v;
        }


        function trigger(name, thisObj) {
            return calendar.trigger.apply(
                calendar,
                [name, thisObj || t].concat(Array.prototype.slice.call(arguments, 2), [t])
            );
        }



        /* Event Editable Boolean Calculations
	------------------------------------------------------------------------------*/


        function isEventDraggable(event) {
            var source = event.source || {};
            return firstDefined(
                event.startEditable,
                source.startEditable,
                opt('eventStartEditable'),
                event.editable,
                source.editable,
                opt('editable')
            );
        }


        function isEventResizable(event) { // but also need to make sure the seg.isEnd == true
            var source = event.source || {};
            return firstDefined(
                event.durationEditable,
                source.durationEditable,
                opt('eventDurationEditable'),
                event.editable,
                source.editable,
                opt('editable')
            );
        }



        /* Event Data
	------------------------------------------------------------------------------*/


        function clearEventData() {
            eventElementsByID = {};
            eventElementCouples = [];
        }



        /* Event Elements
	------------------------------------------------------------------------------*/


        // report when view creates an element for an event
        function reportEventElement(event, element) {
            eventElementCouples.push({ event: event, element: element });
            if (eventElementsByID[event._id]) {
                eventElementsByID[event._id].push(element);
            }else{
                eventElementsByID[event._id] = [element];
            }
        }


        function triggerEventDestroy() {
            $.each(eventElementCouples, function(i, couple) {
                t.trigger('eventDestroy', couple.event, couple.event, couple.element);
            });
        }


        // attaches eventClick, eventMouseover, eventMouseout
        function eventElementHandlers(event, eventElement) {
            eventElement
                .click(function(ev) {
                    if (!eventElement.hasClass('ui-draggable-dragging') &&
                        !eventElement.hasClass('ui-resizable-resizing')) {
                        return trigger('eventClick', this, event, ev);
                    }
                })
                .hover(
                    function(ev) {
                        trigger('eventMouseover', this, event, ev);
                    },
                    function(ev) {
                        trigger('eventMouseout', this, event, ev);
                    }
                );
            // TODO: don't fire eventMouseover/eventMouseout *while* dragging is occuring (on subject element)
            // TODO: same for resizing
        }


        function showEvents(event, exceptElement) {
            eachEventElement(event, exceptElement, 'show');
        }


        function hideEvents(event, exceptElement) {
            eachEventElement(event, exceptElement, 'hide');
        }


        function eachEventElement(event, exceptElement, funcName) {
            // NOTE: there may be multiple events per ID (repeating events)
            // and multiple segments per event
            var elements = eventElementsByID[event._id],
                i, len = elements.length;
            for (i=0; i<len; i++) {
                if (!exceptElement || elements[i][0] != exceptElement[0]) {
                    elements[i][funcName]();
                }
            }
        }


        // Compute the text that should be displayed on an event's element.
        // Based off the settings of the view.
        // Given either an event object or two arguments: a start and end date (which can be null)
        t.getEventTimeText = function(event) {
            var start;
            var end;

            if (arguments.length === 2) {
                start = arguments[0];
                end = arguments[1];
            }
            else {
                start = event.start;
                end = event.end;
            }

            if (end && opt('displayEventEnd')) {
                return calendar.formatRange(start, end, opt('timeFormat'));
            }
            else {
                return calendar.formatDate(start, opt('timeFormat'));
            }
        };



        /* Event Modification Reporting
	---------------------------------------------------------------------------------*/


        function eventDrop(el, event, newStart, ev, ui) {
            var mutateResult = calendar.mutateEvent(event, newStart, null);

            trigger(
                'eventDrop',
                el,
                event,
                mutateResult.dateDelta,
                function() {
                    mutateResult.undo();
                    reportEventChange(event._id);
                },
                ev,
                ui
            );

            reportEventChange(event._id);
        }


        function eventResize(el, event, newEnd, ev, ui) {
            var mutateResult = calendar.mutateEvent(event, null, newEnd);

            trigger(
                'eventResize',
                el,
                event,
                mutateResult.durationDelta,
                function() {
                    mutateResult.undo();
                    reportEventChange(event._id);
                },
                ev,
                ui
            );

            reportEventChange(event._id);
        }


        // ====================================================================================================
        // Utilities for day "cells"
        // ====================================================================================================
        // The "basic" views are completely made up of day cells.
        // The "agenda" views have day cells at the top "all day" slot.
        // This was the obvious common place to put these utilities, but they should be abstracted out into
        // a more meaningful class (like DayEventRenderer).
        // ====================================================================================================


        // For determining how a given "cell" translates into a "date":
        //
        // 1. Convert the "cell" (row and column) into a "cell offset" (the # of the cell, cronologically from the first).
        //    Keep in mind that column indices are inverted with isRTL. This is taken into account.
        //
        // 2. Convert the "cell offset" to a "day offset" (the # of days since the first visible day in the view).
        //
        // 3. Convert the "day offset" into a "date" (a Moment).
        //
        // The reverse transformation happens when transforming a date into a cell.


        // exports
        t.isHiddenDay = isHiddenDay;
        t.skipHiddenDays = skipHiddenDays;
        t.getCellsPerWeek = getCellsPerWeek;
        t.dateToCell = dateToCell;
        t.dateToDayOffset = dateToDayOffset;
        t.dayOffsetToCellOffset = dayOffsetToCellOffset;
        t.cellOffsetToCell = cellOffsetToCell;
        t.cellToDate = cellToDate;
        t.cellToCellOffset = cellToCellOffset;
        t.cellOffsetToDayOffset = cellOffsetToDayOffset;
        t.dayOffsetToDate = dayOffsetToDate;
        t.rangeToSegments = rangeToSegments;


        // internals
        var hiddenDays = opt('hiddenDays') || []; // array of day-of-week indices that are hidden
        var isHiddenDayHash = []; // is the day-of-week hidden? (hash with day-of-week-index -> bool)
        var cellsPerWeek;
        var dayToCellMap = []; // hash from dayIndex -> cellIndex, for one week
        var cellToDayMap = []; // hash from cellIndex -> dayIndex, for one week
        var isRTL = opt('isRTL');


        // initialize important internal variables
        (function() {

            if (opt('weekends') === false) {
                hiddenDays.push(0, 6); // 0=sunday, 6=saturday
            }

            // Loop through a hypothetical week and determine which
            // days-of-week are hidden. Record in both hashes (one is the reverse of the other).
            for (var dayIndex=0, cellIndex=0; dayIndex<7; dayIndex++) {
                dayToCellMap[dayIndex] = cellIndex;
                isHiddenDayHash[dayIndex] = $.inArray(dayIndex, hiddenDays) != -1;
                if (!isHiddenDayHash[dayIndex]) {
                    cellToDayMap[cellIndex] = dayIndex;
                    cellIndex++;
                }
            }

            cellsPerWeek = cellIndex;
            if (!cellsPerWeek) {
                throw 'invalid hiddenDays'; // all days were hidden? bad.
            }

        })();


        // Is the current day hidden?
        // `day` is a day-of-week index (0-6), or a Moment
        function isHiddenDay(day) {
            if (moment.isMoment(day)) {
                day = day.day();
            }
            return isHiddenDayHash[day];
        }


        function getCellsPerWeek() {
            return cellsPerWeek;
        }


        // Incrementing the current day until it is no longer a hidden day, returning a copy.
        // If the initial value of `date` is not a hidden day, don't do anything.
        // Pass `isExclusive` as `true` if you are dealing with an end date.
        // `inc` defaults to `1` (increment one day forward each time)
        function skipHiddenDays(date, inc, isExclusive) {
            var out = date.clone();
            inc = inc || 1;
            while (
                isHiddenDayHash[(out.day() + (isExclusive ? inc : 0) + 7) % 7]
                ) {
                out.add('days', inc);
            }
            return out;
        }


        //
        // TRANSFORMATIONS: cell -> cell offset -> day offset -> date
        //

        // cell -> date (combines all transformations)
        // Possible arguments:
        // - row, col
        // - { row:#, col: # }
        function cellToDate() {
            var cellOffset = cellToCellOffset.apply(null, arguments);
            var dayOffset = cellOffsetToDayOffset(cellOffset);
            var date = dayOffsetToDate(dayOffset);
            return date;
        }

        // cell -> cell offset
        // Possible arguments:
        // - row, col
        // - { row:#, col:# }
        function cellToCellOffset(row, col) {
            var colCnt = t.getColCnt();

            // rtl variables. wish we could pre-populate these. but where?
            var dis = isRTL ? -1 : 1;
            var dit = isRTL ? colCnt - 1 : 0;

            if (typeof row == 'object') {
                col = row.col;
                row = row.row;
            }
            var cellOffset = row * colCnt + (col * dis + dit); // column, adjusted for RTL (dis & dit)

            return cellOffset;
        }

        // cell offset -> day offset
        function cellOffsetToDayOffset(cellOffset) {
            var day0 = t.start.day(); // first date's day of week
            cellOffset += dayToCellMap[day0]; // normlize cellOffset to beginning-of-week
            return Math.floor(cellOffset / cellsPerWeek) * 7 + // # of days from full weeks
                cellToDayMap[ // # of days from partial last week
                (cellOffset % cellsPerWeek + cellsPerWeek) % cellsPerWeek // crazy math to handle negative cellOffsets
                    ] -
                day0; // adjustment for beginning-of-week normalization
        }

        // day offset -> date
        function dayOffsetToDate(dayOffset) {
            return t.start.clone().add('days', dayOffset);
        }


        //
        // TRANSFORMATIONS: date -> day offset -> cell offset -> cell
        //

        // date -> cell (combines all transformations)
        function dateToCell(date) {
            var dayOffset = dateToDayOffset(date);
            var cellOffset = dayOffsetToCellOffset(dayOffset);
            var cell = cellOffsetToCell(cellOffset);
            return cell;
        }

        // date -> day offset
        function dateToDayOffset(date) {
            return date.clone().stripTime().diff(t.start, 'days');
        }

        // day offset -> cell offset
        function dayOffsetToCellOffset(dayOffset) {
            var day0 = t.start.day(); // first date's day of week
            dayOffset += day0; // normalize dayOffset to beginning-of-week
            return Math.floor(dayOffset / 7) * cellsPerWeek + // # of cells from full weeks
                dayToCellMap[ // # of cells from partial last week
                (dayOffset % 7 + 7) % 7 // crazy math to handle negative dayOffsets
                    ] -
                dayToCellMap[day0]; // adjustment for beginning-of-week normalization
        }

        // cell offset -> cell (object with row & col keys)
        function cellOffsetToCell(cellOffset) {
            var colCnt = t.getColCnt();

            // rtl variables. wish we could pre-populate these. but where?
            var dis = isRTL ? -1 : 1;
            var dit = isRTL ? colCnt - 1 : 0;

            var row = Math.floor(cellOffset / colCnt);
            var col = ((cellOffset % colCnt + colCnt) % colCnt) * dis + dit; // column, adjusted for RTL (dis & dit)
            return {
                row: row,
                col: col
            };
        }


        //
        // Converts a date range into an array of segment objects.
        // "Segments" are horizontal stretches of time, sliced up by row.
        // A segment object has the following properties:
        // - row
        // - cols
        // - isStart
        // - isEnd
        //
        function rangeToSegments(start, end) {

            var rowCnt = t.getRowCnt();
            var colCnt = t.getColCnt();
            var segments = []; // array of segments to return

            // day offset for given date range
            var rangeDayOffsetStart = dateToDayOffset(start);
            var rangeDayOffsetEnd = dateToDayOffset(end); // an exclusive value
            var endTimeMS = +end.time();
            if (endTimeMS && endTimeMS >= nextDayThreshold) {
                rangeDayOffsetEnd++;
            }
            rangeDayOffsetEnd = Math.max(rangeDayOffsetEnd, rangeDayOffsetStart + 1);

            // first and last cell offset for the given date range
            // "last" implies inclusivity
            var rangeCellOffsetFirst = dayOffsetToCellOffset(rangeDayOffsetStart);
            var rangeCellOffsetLast = dayOffsetToCellOffset(rangeDayOffsetEnd) - 1;

            // loop through all the rows in the view
            for (var row=0; row<rowCnt; row++) {

                // first and last cell offset for the row
                var rowCellOffsetFirst = row * colCnt;
                var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

                // get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row
                var segmentCellOffsetFirst = Math.max(rangeCellOffsetFirst, rowCellOffsetFirst);
                var segmentCellOffsetLast = Math.min(rangeCellOffsetLast, rowCellOffsetLast);

                // make sure segment's offsets are valid and in view
                if (segmentCellOffsetFirst <= segmentCellOffsetLast) {

                    // translate to cells
                    var segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
                    var segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

                    // view might be RTL, so order by leftmost column
                    var cols = [ segmentCellFirst.col, segmentCellLast.col ].sort();

                    // Determine if segment's first/last cell is the beginning/end of the date range.
                    // We need to compare "day offset" because "cell offsets" are often ambiguous and
                    // can translate to multiple days, and an edge case reveals itself when we the
                    // range's first cell is hidden (we don't want isStart to be true).
                    var isStart = cellOffsetToDayOffset(segmentCellOffsetFirst) == rangeDayOffsetStart;
                    var isEnd = cellOffsetToDayOffset(segmentCellOffsetLast) + 1 == rangeDayOffsetEnd; // +1 for comparing exclusively

                    segments.push({
                        row: row,
                        leftCol: cols[0],
                        rightCol: cols[1],
                        isStart: isStart,
                        isEnd: isEnd
                    });
                }
            }

            return segments;
        }


    }

    ;;

    function DayEventRenderer() {
        var t = this;


        // exports
        t.renderDayEvents = renderDayEvents;
        t.draggableDayEvent = draggableDayEvent; // made public so that subclasses can override
        t.resizableDayEvent = resizableDayEvent; // "


        // imports
        var opt = t.opt;
        var trigger = t.trigger;
        var isEventDraggable = t.isEventDraggable;
        var isEventResizable = t.isEventResizable;
        var reportEventElement = t.reportEventElement;
        var eventElementHandlers = t.eventElementHandlers;
        var showEvents = t.showEvents;
        var hideEvents = t.hideEvents;
        var eventDrop = t.eventDrop;
        var eventResize = t.eventResize;
        var getRowCnt = t.getRowCnt;
        var getColCnt = t.getColCnt;
        var allDayRow = t.allDayRow; // TODO: rename
        var colLeft = t.colLeft;
        var colRight = t.colRight;
        var colContentLeft = t.colContentLeft;
        var colContentRight = t.colContentRight;
        var getDaySegmentContainer = t.getDaySegmentContainer;
        var renderDayOverlay = t.renderDayOverlay;
        var clearOverlays = t.clearOverlays;
        var clearSelection = t.clearSelection;
        var getHoverListener = t.getHoverListener;
        var rangeToSegments = t.rangeToSegments;
        var cellToDate = t.cellToDate;
        var cellToCellOffset = t.cellToCellOffset;
        var cellOffsetToDayOffset = t.cellOffsetToDayOffset;
        var dateToDayOffset = t.dateToDayOffset;
        var dayOffsetToCellOffset = t.dayOffsetToCellOffset;
        var calendar = t.calendar;
        var getEventEnd = calendar.getEventEnd;


        // Render `events` onto the calendar, attach mouse event handlers, and call the `eventAfterRender` callback for each.
        // Mouse event will be lazily applied, except if the event has an ID of `modifiedEventId`.
        // Can only be called when the event container is empty (because it wipes out all innerHTML).
        function renderDayEvents(events, modifiedEventId) {

            // do the actual rendering. Receive the intermediate "segment" data structures.
            var segments = _renderDayEvents(
                events,
                false, // don't append event elements
                true // set the heights of the rows
            );

            // report the elements to the View, for general drag/resize utilities
            segmentElementEach(segments, function(segment, element) {
                reportEventElement(segment.event, element);
            });

            // attach mouse handlers
            attachHandlers(segments, modifiedEventId);

            // call `eventAfterRender` callback for each event
            segmentElementEach(segments, function(segment, element) {
                trigger('eventAfterRender', segment.event, segment.event, element);
            });
        }


        // Render an event on the calendar, but don't report them anywhere, and don't attach mouse handlers.
        // Append this event element to the event container, which might already be populated with events.
        // If an event's segment will have row equal to `adjustRow`, then explicitly set its top coordinate to `adjustTop`.
        // This hack is used to maintain continuity when user is manually resizing an event.
        // Returns an array of DOM elements for the event.
        function renderTempDayEvent(event, adjustRow, adjustTop) {

            // actually render the event. `true` for appending element to container.
            // Recieve the intermediate "segment" data structures.
            var segments = _renderDayEvents(
                [ event ],
                true, // append event elements
                false // don't set the heights of the rows
            );

            var elements = [];

            // Adjust certain elements' top coordinates
            segmentElementEach(segments, function(segment, element) {
                if (segment.row === adjustRow) {
                    element.css('top', adjustTop);
                }
                elements.push(element[0]); // accumulate DOM nodes
            });

            return elements;
        }


        // Render events onto the calendar. Only responsible for the VISUAL aspect.
        // Not responsible for attaching handlers or calling callbacks.
        // Set `doAppend` to `true` for rendering elements without clearing the existing container.
        // Set `doRowHeights` to allow setting the height of each row, to compensate for vertical event overflow.
        function _renderDayEvents(events, doAppend, doRowHeights) {

            // where the DOM nodes will eventually end up
            var finalContainer = getDaySegmentContainer();

            // the container where the initial HTML will be rendered.
            // If `doAppend`==true, uses a temporary container.
            var renderContainer = doAppend ? $("<div/>") : finalContainer;

            var segments = buildSegments(events);
            var html;
            var elements;

            // calculate the desired `left` and `width` properties on each segment object
            calculateHorizontals(segments);

            // build the HTML string. relies on `left` property
            html = buildHTML(segments);

            // render the HTML. innerHTML is considerably faster than jQuery's .html()
            renderContainer[0].innerHTML = html;

            // retrieve the individual elements
            elements = renderContainer.children();

            // if we were appending, and thus using a temporary container,
            // re-attach elements to the real container.
            if (doAppend) {
                finalContainer.append(elements);
            }

            // assigns each element to `segment.event`, after filtering them through user callbacks
            resolveElements(segments, elements);

            // Calculate the left and right padding+margin for each element.
            // We need this for setting each element's desired outer width, because of the W3C box model.
            // It's important we do this in a separate pass from acually setting the width on the DOM elements
            // because alternating reading/writing dimensions causes reflow for every iteration.
            segmentElementEach(segments, function(segment, element) {
                segment.hsides = hsides(element, true); // include margins = `true`
            });

            // Set the width of each element
            segmentElementEach(segments, function(segment, element) {
                element.width(
                    Math.max(0, segment.outerWidth - segment.hsides)
                );
            });

            // Grab each element's outerHeight (setVerticals uses this).
            // To get an accurate reading, it's important to have each element's width explicitly set already.
            segmentElementEach(segments, function(segment, element) {
                segment.outerHeight = element.outerHeight(true); // include margins = `true`
            });

            // Set the top coordinate on each element (requires segment.outerHeight)
            setVerticals(segments, doRowHeights);

            return segments;
        }


        // Generate an array of "segments" for all events.
        function buildSegments(events) {
            var segments = [];
            for (var i=0; i<events.length; i++) {
                var eventSegments = buildSegmentsForEvent(events[i]);
                segments.push.apply(segments, eventSegments); // append an array to an array
            }
            return segments;
        }


        // Generate an array of segments for a single event.
        // A "segment" is the same data structure that View.rangeToSegments produces,
        // with the addition of the `event` property being set to reference the original event.
        function buildSegmentsForEvent(event) {
            var segments = rangeToSegments(event.start, getEventEnd(event));
            for (var i=0; i<segments.length; i++) {
                segments[i].event = event;
            }
            return segments;
        }


        // Sets the `left` and `outerWidth` property of each segment.
        // These values are the desired dimensions for the eventual DOM elements.
        function calculateHorizontals(segments) {
            var isRTL = opt('isRTL');
            for (var i=0; i<segments.length; i++) {
                var segment = segments[i];

                // Determine functions used for calulating the elements left/right coordinates,
                // depending on whether the view is RTL or not.
                // NOTE:
                // colLeft/colRight returns the coordinate butting up the edge of the cell.
                // colContentLeft/colContentRight is indented a little bit from the edge.
                var leftFunc = (isRTL ? segment.isEnd : segment.isStart) ? colContentLeft : colLeft;
                var rightFunc = (isRTL ? segment.isStart : segment.isEnd) ? colContentRight : colRight;

                var left = leftFunc(segment.leftCol);
                var right = rightFunc(segment.rightCol);
                segment.left = left;
                segment.outerWidth = right - left;
            }
        }


        // Build a concatenated HTML string for an array of segments
        function buildHTML(segments) {
            var html = '';
            for (var i=0; i<segments.length; i++) {
                html += buildHTMLForSegment(segments[i]);
            }
            return html;
        }


        // Build an HTML string for a single segment.
        // Relies on the following properties:
        // - `segment.event` (from `buildSegmentsForEvent`)
        // - `segment.left` (from `calculateHorizontals`)
        function buildHTMLForSegment(segment) {
            var html = '';
            var isRTL = opt('isRTL');
            var event = segment.event;
            var url = event.url;

            // generate the list of CSS classNames
            var classNames = [ 'fc-event', 'fc-event-hori' ];
            if (isEventDraggable(event)) {
                classNames.push('fc-event-draggable');
            }
            if (segment.isStart) {
                classNames.push('fc-event-start');
            }
            if (segment.isEnd) {
                classNames.push('fc-event-end');
            }
            // use the event's configured classNames
            // guaranteed to be an array via `buildEvent`
            classNames = classNames.concat(event.className);
            if (event.source) {
                // use the event's source's classNames, if specified
                classNames = classNames.concat(event.source.className || []);
            }

            // generate a semicolon delimited CSS string for any of the "skin" properties
            // of the event object (`backgroundColor`, `borderColor` and such)
            var skinCss = getSkinCss(event, opt);

            if (url) {
                html += "<a href='" + htmlEscape(url) + "'";
            }else{
                html += "<div";
            }
            html +=
                " class='" + classNames.join(' ') + "'" +
                " style=" +
                "'" +
                "position:absolute;" +
                "left:" + segment.left + "px;" +
                skinCss +
                "'" +
                ">" +
                "<div class='fc-event-inner'>";
            if (!event.allDay && segment.isStart) {
                html +=
                    "<span class='fc-event-time'>" +
                    htmlEscape(t.getEventTimeText(event)) +
                    "</span>";
            }
            html +=
                "<span class='fc-event-title'>" +
                htmlEscape(event.title || '') +
                "</span>" +
                "</div>";
            if (event.allDay && segment.isEnd && isEventResizable(event)) {
                html +=
                    "<div class='ui-resizable-handle ui-resizable-" + (isRTL ? 'w' : 'e') + "'>" +
                    "&nbsp;&nbsp;&nbsp;" + // makes hit area a lot better for IE6/7
                    "</div>";
            }
            html += "</" + (url ? "a" : "div") + ">";

            // TODO:
            // When these elements are initially rendered, they will be briefly visibile on the screen,
            // even though their widths/heights are not set.
            // SOLUTION: initially set them as visibility:hidden ?

            return html;
        }


        // Associate each segment (an object) with an element (a jQuery object),
        // by setting each `segment.element`.
        // Run each element through the `eventRender` filter, which allows developers to
        // modify an existing element, supply a new one, or cancel rendering.
        function resolveElements(segments, elements) {
            for (var i=0; i<segments.length; i++) {
                var segment = segments[i];
                var event = segment.event;
                var element = elements.eq(i);

                // call the trigger with the original element
                var triggerRes = trigger('eventRender', event, event, element);

                if (triggerRes === false) {
                    // if `false`, remove the event from the DOM and don't assign it to `segment.event`
                    element.remove();
                }
                else {
                    if (triggerRes && triggerRes !== true) {
                        // the trigger returned a new element, but not `true` (which means keep the existing element)

                        // re-assign the important CSS dimension properties that were already assigned in `buildHTMLForSegment`
                        triggerRes = $(triggerRes)
                            .css({
                                position: 'absolute',
                                left: segment.left
                            });

                        element.replaceWith(triggerRes);
                        element = triggerRes;
                    }

                    segment.element = element;
                }
            }
        }



        /* Top-coordinate Methods
	-------------------------------------------------------------------------------------------------*/


        // Sets the "top" CSS property for each element.
        // If `doRowHeights` is `true`, also sets each row's first cell to an explicit height,
        // so that if elements vertically overflow, the cell expands vertically to compensate.
        function setVerticals(segments, doRowHeights) {
            var rowContentHeights = calculateVerticals(segments); // also sets segment.top
            var rowContentElements = getRowContentElements(); // returns 1 inner div per row
            var rowContentTops = [];
            var i;

            // Set each row's height by setting height of first inner div
            if (doRowHeights) {
                for (i=0; i<rowContentElements.length; i++) {
                    rowContentElements[i].height(rowContentHeights[i]);
                }
            }

            // Get each row's top, relative to the views's origin.
            // Important to do this after setting each row's height.
            for (i=0; i<rowContentElements.length; i++) {
                rowContentTops.push(
                    rowContentElements[i].position().top
                );
            }

            // Set each segment element's CSS "top" property.
            // Each segment object has a "top" property, which is relative to the row's top, but...
            segmentElementEach(segments, function(segment, element) {
                element.css(
                    'top',
                    rowContentTops[segment.row] + segment.top // ...now, relative to views's origin
                );
            });
        }


        // Calculate the "top" coordinate for each segment, relative to the "top" of the row.
        // Also, return an array that contains the "content" height for each row
        // (the height displaced by the vertically stacked events in the row).
        // Requires segments to have their `outerHeight` property already set.
        function calculateVerticals(segments) {
            var rowCnt = getRowCnt();
            var colCnt = getColCnt();
            var rowContentHeights = []; // content height for each row
            var segmentRows = buildSegmentRows(segments); // an array of segment arrays, one for each row
            var colI;

            for (var rowI=0; rowI<rowCnt; rowI++) {
                var segmentRow = segmentRows[rowI];

                // an array of running total heights for each column.
                // initialize with all zeros.
                var colHeights = [];
                for (colI=0; colI<colCnt; colI++) {
                    colHeights.push(0);
                }

                // loop through every segment
                for (var segmentI=0; segmentI<segmentRow.length; segmentI++) {
                    var segment = segmentRow[segmentI];

                    // find the segment's top coordinate by looking at the max height
                    // of all the columns the segment will be in.
                    segment.top = arrayMax(
                        colHeights.slice(
                            segment.leftCol,
                            segment.rightCol + 1 // make exclusive for slice
                        )
                    );

                    // adjust the columns to account for the segment's height
                    for (colI=segment.leftCol; colI<=segment.rightCol; colI++) {
                        colHeights[colI] = segment.top + segment.outerHeight;
                    }
                }

                // the tallest column in the row should be the "content height"
                rowContentHeights.push(arrayMax(colHeights));
            }

            return rowContentHeights;
        }


        // Build an array of segment arrays, each representing the segments that will
        // be in a row of the grid, sorted by which event should be closest to the top.
        function buildSegmentRows(segments) {
            var rowCnt = getRowCnt();
            var segmentRows = [];
            var segmentI;
            var segment;
            var rowI;

            // group segments by row
            for (segmentI=0; segmentI<segments.length; segmentI++) {
                segment = segments[segmentI];
                rowI = segment.row;
                if (segment.element) { // was rendered?
                    if (segmentRows[rowI]) {
                        // already other segments. append to array
                        segmentRows[rowI].push(segment);
                    }
                    else {
                        // first segment in row. create new array
                        segmentRows[rowI] = [ segment ];
                    }
                }
            }

            // sort each row
            for (rowI=0; rowI<rowCnt; rowI++) {
                segmentRows[rowI] = sortSegmentRow(
                    segmentRows[rowI] || [] // guarantee an array, even if no segments
                );
            }

            return segmentRows;
        }


        // Sort an array of segments according to which segment should appear closest to the top
        function sortSegmentRow(segments) {
            var sortedSegments = [];

            // build the subrow array
            var subrows = buildSegmentSubrows(segments);

            // flatten it
            for (var i=0; i<subrows.length; i++) {
                sortedSegments.push.apply(sortedSegments, subrows[i]); // append an array to an array
            }

            return sortedSegments;
        }


        // Take an array of segments, which are all assumed to be in the same row,
        // and sort into subrows.
        function buildSegmentSubrows(segments) {

            // Give preference to elements with certain criteria, so they have
            // a chance to be closer to the top.
            segments.sort(compareDaySegments);

            var subrows = [];
            for (var i=0; i<segments.length; i++) {
                var segment = segments[i];

                // loop through subrows, starting with the topmost, until the segment
                // doesn't collide with other segments.
                for (var j=0; j<subrows.length; j++) {
                    if (!isDaySegmentCollision(segment, subrows[j])) {
                        break;
                    }
                }
                // `j` now holds the desired subrow index
                if (subrows[j]) {
                    subrows[j].push(segment);
                }
                else {
                    subrows[j] = [ segment ];
                }
            }

            return subrows;
        }


        // Return an array of jQuery objects for the placeholder content containers of each row.
        // The content containers don't actually contain anything, but their dimensions should match
        // the events that are overlaid on top.
        function getRowContentElements() {
            var i;
            var rowCnt = getRowCnt();
            var rowDivs = [];
            for (i=0; i<rowCnt; i++) {
                rowDivs[i] = allDayRow(i)
                    .find('div.fc-day-content > div');
            }
            return rowDivs;
        }



        /* Mouse Handlers
	---------------------------------------------------------------------------------------------------*/
        // TODO: better documentation!


        function attachHandlers(segments, modifiedEventId) {
            var segmentContainer = getDaySegmentContainer();

            segmentElementEach(segments, function(segment, element, i) {
                var event = segment.event;
                if (event._id === modifiedEventId) {
                    bindDaySeg(event, element, segment);
                }else{
                    element[0]._fci = i; // for lazySegBind
                }
            });

            lazySegBind(segmentContainer, segments, bindDaySeg);
        }


        function bindDaySeg(event, eventElement, segment) {

            if (isEventDraggable(event)) {
                t.draggableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
            }

            if (
                event.allDay &&
                segment.isEnd && // only allow resizing on the final segment for an event
                isEventResizable(event)
            ) {
                t.resizableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
            }

            // attach all other handlers.
            // needs to be after, because resizableDayEvent might stopImmediatePropagation on click
            eventElementHandlers(event, eventElement);
        }


        function draggableDayEvent(event, eventElement) {
            var hoverListener = getHoverListener();
            var dayDelta;
            var eventStart;
            eventElement.draggable({
                delay: 50,
                opacity: opt('dragOpacity'),
                revertDuration: opt('dragRevertDuration'),
                start: function(ev, ui) {
                    trigger('eventDragStart', eventElement[0], event, ev, ui);
                    hideEvents(event, eventElement);
                    hoverListener.start(function(cell, origCell, rowDelta, colDelta) {
                        eventElement.draggable('option', 'revert', !cell || !rowDelta && !colDelta);
                        clearOverlays();
                        if (cell) {
                            var origCellDate = cellToDate(origCell);
                            var cellDate = cellToDate(cell);
                            dayDelta = cellDate.diff(origCellDate, 'days');
                            eventStart = event.start.clone().add('days', dayDelta);
                            renderDayOverlay(
                                eventStart,
                                getEventEnd(event).add('days', dayDelta)
                            );
                        }
                        else {
                            dayDelta = 0;
                        }
                    }, ev, 'drag');
                },
                stop: function(ev, ui) {
                    hoverListener.stop();
                    clearOverlays();
                    trigger('eventDragStop', eventElement[0], event, ev, ui);
                    if (dayDelta) {
                        eventDrop(
                            eventElement[0],
                            event,
                            eventStart,
                            ev,
                            ui
                        );
                    }
                    else {
                        eventElement.css('filter', ''); // clear IE opacity side-effects
                        showEvents(event, eventElement);
                    }
                }
            });
        }


        function resizableDayEvent(event, element, segment) {
            var isRTL = opt('isRTL');
            var direction = isRTL ? 'w' : 'e';
            var handle = element.find('.ui-resizable-' + direction); // TODO: stop using this class because we aren't using jqui for this
            var isResizing = false;

            // TODO: look into using jquery-ui mouse widget for this stuff
            disableTextSelection(element); // prevent native <a> selection for IE
            element
                .mousedown(function(ev) { // prevent native <a> selection for others
                    ev.preventDefault();
                })
                .click(function(ev) {
                    if (isResizing) {
                        ev.preventDefault(); // prevent link from being visited (only method that worked in IE6)
                        ev.stopImmediatePropagation(); // prevent fullcalendar eventClick handler from being called
                                                       // (eventElementHandlers needs to be bound after resizableDayEvent)
                    }
                });

            handle.mousedown(function(ev) {
                if (ev.which != 1) {
                    return; // needs to be left mouse button
                }
                isResizing = true;
                var hoverListener = getHoverListener();
                var elementTop = element.css('top');
                var dayDelta;
                var eventEnd;
                var helpers;
                var eventCopy = $.extend({}, event);
                var minCellOffset = dayOffsetToCellOffset(dateToDayOffset(event.start));
                clearSelection();
                $('body')
                    .css('cursor', direction + '-resize')
                    .one('mouseup', mouseup);
                trigger('eventResizeStart', element[0], event, ev, {}); // {} is dummy jqui event
                hoverListener.start(function(cell, origCell) {
                    if (cell) {

                        var origCellOffset = cellToCellOffset(origCell);
                        var cellOffset = cellToCellOffset(cell);

                        // don't let resizing move earlier than start date cell
                        cellOffset = Math.max(cellOffset, minCellOffset);

                        dayDelta =
                            cellOffsetToDayOffset(cellOffset) -
                            cellOffsetToDayOffset(origCellOffset);

                        eventEnd = getEventEnd(event).add('days', dayDelta); // assumed to already have a stripped time

                        if (dayDelta) {
                            eventCopy.end = eventEnd;
                            var oldHelpers = helpers;
                            helpers = renderTempDayEvent(eventCopy, segment.row, elementTop);
                            helpers = $(helpers); // turn array into a jQuery object
                            helpers.find('*').css('cursor', direction + '-resize');
                            if (oldHelpers) {
                                oldHelpers.remove();
                            }
                            hideEvents(event);
                        }
                        else {
                            if (helpers) {
                                showEvents(event);
                                helpers.remove();
                                helpers = null;
                            }
                        }

                        clearOverlays();
                        renderDayOverlay( // coordinate grid already rebuilt with hoverListener.start()
                            event.start,
                            eventEnd
                            // TODO: instead of calling renderDayOverlay() with dates,
                            // call _renderDayOverlay (or whatever) with cell offsets.
                        );
                    }
                }, ev);

                function mouseup(ev) {
                    trigger('eventResizeStop', element[0], event, ev, {}); // {} is dummy jqui event
                    $('body').css('cursor', '');
                    hoverListener.stop();
                    clearOverlays();

                    if (dayDelta) {
                        eventResize(
                            element[0],
                            event,
                            eventEnd,
                            ev,
                            {} // dummy jqui event
                        );
                        // event redraw will clear helpers
                    }
                    // otherwise, the drag handler already restored the old events

                    setTimeout(function() { // make this happen after the element's click event
                        isResizing = false;
                    },0);
                }
            });
        }


    }



    /* Generalized Segment Utilities
-------------------------------------------------------------------------------------------------*/


    function isDaySegmentCollision(segment, otherSegments) {
        for (var i=0; i<otherSegments.length; i++) {
            var otherSegment = otherSegments[i];
            if (
                otherSegment.leftCol <= segment.rightCol &&
                otherSegment.rightCol >= segment.leftCol
            ) {
                return true;
            }
        }
        return false;
    }


    function segmentElementEach(segments, callback) { // TODO: use in AgendaView?
        for (var i=0; i<segments.length; i++) {
            var segment = segments[i];
            var element = segment.element;
            if (element) {
                callback(segment, element, i);
            }
        }
    }


// A cmp function for determining which segments should appear higher up
    function compareDaySegments(a, b) {
        return (b.rightCol - b.leftCol) - (a.rightCol - a.leftCol) || // put wider events first
            b.event.allDay - a.event.allDay || // if tie, put all-day events first (booleans cast to 0/1)
            a.event.start - b.event.start || // if a tie, sort by event start date
            (a.event.title || '').localeCompare(b.event.title); // if a tie, sort by event title
    }


    ;;

//BUG: unselect needs to be triggered when events are dragged+dropped

    function SelectionManager() {
        var t = this;


        // exports
        t.select = select;
        t.unselect = unselect;
        t.reportSelection = reportSelection;
        t.daySelectionMousedown = daySelectionMousedown;
        t.selectionManagerDestroy = destroy;


        // imports
        var calendar = t.calendar;
        var opt = t.opt;
        var trigger = t.trigger;
        var defaultSelectionEnd = t.defaultSelectionEnd;
        var renderSelection = t.renderSelection;
        var clearSelection = t.clearSelection;


        // locals
        var selected = false;



        // unselectAuto
        if (opt('selectable') && opt('unselectAuto')) {
            $(document).on('mousedown', documentMousedown);
        }


        function documentMousedown(ev) {
            var ignore = opt('unselectCancel');
            if (ignore) {
                if ($(ev.target).parents(ignore).length) { // could be optimized to stop after first match
                    return;
                }
            }
            unselect(ev);
        }


        function select(start, end) {
            unselect();

            start = calendar.moment(start);
            if (end) {
                end = calendar.moment(end);
            }
            else {
                end = defaultSelectionEnd(start);
            }

            renderSelection(start, end);
            reportSelection(start, end);
        }
        // TODO: better date normalization. see notes in automated test


        function unselect(ev) {
            if (selected) {
                selected = false;
                clearSelection();
                trigger('unselect', null, ev);
            }
        }


        function reportSelection(start, end, ev) {
            selected = true;
            trigger('select', null, start, end, ev);
        }


        function daySelectionMousedown(ev) { // not really a generic manager method, oh well
            var cellToDate = t.cellToDate;
            var getIsCellAllDay = t.getIsCellAllDay;
            var hoverListener = t.getHoverListener();
            var reportDayClick = t.reportDayClick; // this is hacky and sort of weird

            if (ev.which == 1 && opt('selectable')) { // which==1 means left mouse button
                unselect(ev);
                var dates;
                hoverListener.start(function(cell, origCell) { // TODO: maybe put cellToDate/getIsCellAllDay info in cell
                    clearSelection();
                    if (cell && getIsCellAllDay(cell)) {
                        dates = [ cellToDate(origCell), cellToDate(cell) ].sort(dateCompare);
                        renderSelection(
                            dates[0],
                            dates[1].clone().add('days', 1) // make exclusive
                        );
                    }else{
                        dates = null;
                    }
                }, ev);
                $(document).one('mouseup', function(ev) {
                    hoverListener.stop();
                    if (dates) {
                        if (+dates[0] == +dates[1]) {
                            reportDayClick(dates[0], ev);
                        }
                        reportSelection(
                            dates[0],
                            dates[1].clone().add('days', 1), // make exclusive
                            ev
                        );
                    }
                });
            }
        }


        function destroy() {
            $(document).off('mousedown', documentMousedown);
        }


    }

    ;;

    function OverlayManager() {
        var t = this;


        // exports
        t.renderOverlay = renderOverlay;
        t.clearOverlays = clearOverlays;


        // locals
        var usedOverlays = [];
        var unusedOverlays = [];


        function renderOverlay(rect, parent) {
            var e = unusedOverlays.shift();
            if (!e) {
                e = $("<div class='fc-cell-overlay' style='position:absolute;z-index:3'/>");
            }
            if (e[0].parentNode != parent[0]) {
                e.appendTo(parent);
            }
            usedOverlays.push(e.css(rect).show());
            return e;
        }


        function clearOverlays() {
            var e;
            while ((e = usedOverlays.shift())) {
                unusedOverlays.push(e.hide().unbind());
            }
        }


    }

    ;;

    function CoordinateGrid(buildFunc) {

        var t = this;
        var rows;
        var cols;


        t.build = function() {
            rows = [];
            cols = [];
            buildFunc(rows, cols);
        };


        t.cell = function(x, y) {
            var rowCnt = rows.length;
            var colCnt = cols.length;
            var i, r=-1, c=-1;
            for (i=0; i<rowCnt; i++) {
                if (y >= rows[i][0] && y < rows[i][1]) {
                    r = i;
                    break;
                }
            }
            for (i=0; i<colCnt; i++) {
                if (x >= cols[i][0] && x < cols[i][1]) {
                    c = i;
                    break;
                }
            }
            return (r>=0 && c>=0) ? { row: r, col: c } : null;
        };


        t.rect = function(row0, col0, row1, col1, originElement) { // row1,col1 is inclusive
            var origin = originElement.offset();
            return {
                top: rows[row0][0] - origin.top,
                left: cols[col0][0] - origin.left,
                width: cols[col1][1] - cols[col0][0],
                height: rows[row1][1] - rows[row0][0]
            };
        };

    }

    ;;

    function HoverListener(coordinateGrid) {


        var t = this;
        var bindType;
        var change;
        var firstCell;
        var cell;


        t.start = function(_change, ev, _bindType) {
            change = _change;
            firstCell = cell = null;
            coordinateGrid.build();
            mouse(ev);
            bindType = _bindType || 'mousemove';
            $(document).bind(bindType, mouse);
        };


        function mouse(ev) {
            _fixUIEvent(ev); // see below
            var newCell = coordinateGrid.cell(ev.pageX, ev.pageY);
            if (
                Boolean(newCell) !== Boolean(cell) ||
                newCell && (newCell.row != cell.row || newCell.col != cell.col)
            ) {
                if (newCell) {
                    if (!firstCell) {
                        firstCell = newCell;
                    }
                    change(newCell, firstCell, newCell.row-firstCell.row, newCell.col-firstCell.col);
                }else{
                    change(newCell, firstCell);
                }
                cell = newCell;
            }
        }


        t.stop = function() {
            $(document).unbind(bindType, mouse);
            return cell;
        };


    }



// this fix was only necessary for jQuery UI 1.8.16 (and jQuery 1.7 or 1.7.1)
// upgrading to jQuery UI 1.8.17 (and using either jQuery 1.7 or 1.7.1) fixed the problem
// but keep this in here for 1.8.16 users
// and maybe remove it down the line

    function _fixUIEvent(event) { // for issue 1168
        if (event.pageX === undefined) {
            event.pageX = event.originalEvent.pageX;
            event.pageY = event.originalEvent.pageY;
        }
    }
    ;;

    function HorizontalPositionCache(getElement) {

        var t = this,
            elements = {},
            lefts = {},
            rights = {};

        function e(i) {
            return (elements[i] = (elements[i] || getElement(i)));
        }

        t.left = function(i) {
            return (lefts[i] = (lefts[i] === undefined ? e(i).position().left : lefts[i]));
        };

        t.right = function(i) {
            return (rights[i] = (rights[i] === undefined ? t.left(i) + e(i).width() : rights[i]));
        };

        t.clear = function() {
            elements = {};
            lefts = {};
            rights = {};
        };

    }

    ;;

});
